<template>
  <transition :name="`slide-${transition}`" appear>
    <div class="w-full p-4">
      <h2 class="font-bold text-xl sm:text-2xl w-full text-left">
        Do you want to assign an Advocate for {{ personText }}?
      </h2>
      <div class="w-full my-4">
        <Select
          @select="setAdvocate"
          :label="''"
          placeholder="Select an Advocate"
          :modelValue="advocate"
          :options="options"
        />
      </div>
      <Actions @previous="previousStep" @next="assignAdvocate">
        <template #previous>
          <span>{{ language.back }}</span>
        </template>
        <template #next>
          <span>{{ language.next }}</span>
        </template>
      </Actions>
    </div>
  </transition>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { strArrayToSelectOptions } from "../../utils/functions";
import useBenefitRecord from "../../composables/useBenefitRecord";
import useQuickscreen from "../../composables/useQuickscreen";
import useApp from "../../composables/useApp";
import API from "../../services/benefitScreeningAPI";
import Select from "../../components/common/ui/Select.vue";
import Actions from "../../components/screen/ui/Actions.vue";
import useLanguage from "../../composables/useLanguage";
export default {
  components: {
    Select,
    Actions,
  },
  setup() {
    const { language } = useLanguage();
    const { benefitRecordId, firstName } = useBenefitRecord();
    const { transition, previousStep, nextStep } = useQuickscreen();
    const { setLoading } = useApp();
    const advocates = ref([]);
    const advocate = ref("None");

    onMounted(async () => {
      const response = await API.getAdvocates();
      advocates.value = response.data;
    });

    const setAdvocate = (_advocate) => {
      advocate.value = _advocate;
    };

    const options = computed(() => {
      const options = strArrayToSelectOptions(advocates.value);
      options.unshift({
        text: "None",
        value: "None",
      });
      return options;
    });

    const personText = computed(() => {
      return firstName.value !== "New" && firstName.value
        ? firstName.value
        : "this person";
    });

    const assignAdvocate = async () => {
      if (advocate.value !== "None") {
        setLoading(true);
        const payload = {
          email: advocate.value,
          benefitRecordId: benefitRecordId.value,
        };
        try {
          await API.setAdvocate(payload);
        } catch (error) {
          console.log(error);
        }
      }
      setLoading(false);
      nextStep();
    };

    return {
      transition,
      language,
      personText,
      options,
      advocates,
      advocate,
      assignAdvocate,
      previousStep,
      setAdvocate,
    };
  },
};
</script>

<style></style>
