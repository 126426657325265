<template>
  <div class="w-full p-4">
    <EligiblePrograms />
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import useBenefitRecord from "../../../../composables/useBenefitRecord";
import EligiblePrograms from "../../ui/EligiblePrograms.vue";
export default {
  components: {
    EligiblePrograms,
  },
  setup() {
    const { saveBenefitRecord } = useBenefitRecord();

    onMounted(async () => {
      await saveBenefitRecord();
    });
  },
};
</script>

<style></style>
