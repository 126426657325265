<template>
  <h3
    class="text-primary w-full text-center font-bold uppercase text-sm mb-2 font-secondary"
  >
    <span v-if="questionHeaderType === questionHeaderTypes.question && !isAdditionalQuestion()">
      {{ language.question }} {{ getQuestionProgress() }}
    </span>
    <span v-else-if="questionHeaderType === questionHeaderTypes.progress && !isAdditionalQuestion()">
      {{ language.progress }} {{ progress }}%
    </span>
  </h3>
</template>

<script>
import { questionHeaderTypes } from "../../../common/facilities/index";
import useQuickscreen from "../../../composables/useQuickscreen";
import useLanguage from "../../../composables/useLanguage";
export default {
  setup() {
    const {
      currentQuestionIndex,
      script,
      progress,
      questionHeaderType,
      questionListIndex,
      currentQuestion,
    } = useQuickscreen();
    const { language } = useLanguage();

    const getQuestionProgress = () => {
      console.log("Question list index: " + questionListIndex.value);

      const totalQuestions = script.value.filter(
        (q) => q.listIndex === questionListIndex.value
      ).length;
      console.log("totalQuestions: " + totalQuestions);
      console.log("current question: " + currentQuestion.value);
      const currentIndex = script.value
        .filter((q) => q.listIndex === questionListIndex.value)
        .findIndex(
          (question) => question.questionGuid === currentQuestion.value
        );
      console.log("currentIndex: " + currentIndex);

      //return $(currentIndex + 1 / totalQuestions);
      return `${currentIndex + 1} / ${totalQuestions}`;
    };

    const isAdditionalQuestion = () => {
      const question = script.value.find(q => q.questionGuid === currentQuestion.value);
      const isAdditional = question.listIndex > 0;
      return isAdditional;
    }

    return {
      script,
      questionHeaderType,
      questionHeaderTypes,
      currentQuestionIndex,
      totalQuestions: script.value.length,
      progress,
      language,
      questionListIndex,
      currentQuestion,
      getQuestionProgress,
      isAdditionalQuestion,
    };
  },
};
</script>

<style></style>
