<template>
  <div class="w-full p-4">
    <EligiblePrograms />
    <p class="w-full text-center my-8 text-sm">
      {{ language.uniteUsCongrats }}
    </p>
    <Actions @previous="previousStep" @next="redirectToUniteUs" class="mt-4">
      <template #previous>
        <span>{{ language.back }}</span>
      </template>
      <template #next>
        <span>{{ language.requestAssistance }}</span>
      </template>
    </Actions>
  </div>
</template>

<script>
import { useGtag } from "vue-gtag-next";
import useQuickscreen from "../../../../composables/useQuickscreen";
import useBenefitRecord from "../../../../composables/useBenefitRecord";
import useLanguage from "../../../../composables/useLanguage";
import EligiblePrograms from "../../ui/EligiblePrograms.vue";
import Actions from "../../ui/Actions.vue";
import useApp from "../../../../composables/useApp";
export default {
  components: {
    EligiblePrograms,
    Actions,
  },
  setup() {
    const { setLoading } = useApp();
    const { saveBenefitRecord } = useBenefitRecord();
    const { transition, previousStep } = useQuickscreen();
    const { query } = useGtag();
    const { language } = useLanguage();
    const redirectToUniteUs = async () => {
      setLoading(true);
      await saveBenefitRecord();
      setLoading(false);
      query("event", "redirect", {
        event_category: "https://louisiana.uniteus.com/get-help/",
      });
      window.location = "https://louisiana.uniteus.com/get-help/";
    };

    return {
      language,
      transition,
      previousStep,
      redirectToUniteUs,
    };
  },
};
</script>

<style></style>
