<template>
  <div class="w-full">
    <div class="w-full bg-white">
      <div class="w-full relative" v-show="isLoaded">
        <img
          src="https://quickscreen-assets.s3.us-east-2.amazonaws.com/img/iStock-131976321_transparent_opacity.png"
          alt=""
          @load="loaded"
        />
        <div
          class="absolute top-0 left-0 right-0 bottom-0 text-right flex justify-end"
        ></div>
      </div>
      <div class="w-full my-4 px-2 sm:px-0">
        <h2 class="font-bold w-full text-left font-secondary">
          Answering the following questions is voluntary and confidential but
          will help Ascension Illinois better serve each individual. Collecting data is
          critical to documenting our progress in the elimination of health
          disparities and improving the health of the community.
        </h2>

        <div class="my-4 w-full">
          <div class="w-full">
            <button
              @click="
                setAnswer('Yes');
                nextStep();
              "
              class="flex justify-between items-center px-4 py-3 my-2 rounded-md border shadow-sm bg-white w-full"
              :class="
                answer === 'Yes'
                  ? 'border-primary cursor-default'
                  : 'hover:bg-gray-100'
              "
            >
              <div>Continue to questions</div>
              <div
                class="flex justify-center items-center w-6 h-6 border rounded-full"
                :class="
                  answer === 'Yes'
                    ? 'border-primary bg-primary-light text-primary'
                    : 'border-gray-400'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="ai ai-Check"
                  v-if="answer === 'Yes'"
                >
                  <path d="M4 12l6 6L20 6" />
                </svg>
              </div>
            </button>
            <button
              @click="
                setAnswer('No');
                lastStep();
              "
              class="flex justify-between items-center px-4 py-3 my-2 rounded-md border shadow-sm bg-white w-full"
              :class="
                answer === 'No'
                  ? 'border-primary cursor-default'
                  : 'hover:bg-gray-100'
              "
            >
              <div>No thanks</div>
              <div
                class="flex justify-center items-center w-6 h-6 border rounded-full"
                :class="
                  answer === 'No'
                    ? 'border-primary bg-primary-light text-primary'
                    : 'border-gray-400'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="ai ai-Check"
                  v-if="answer === 'No'"
                >
                  <path d="M4 12l6 6L20 6" />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { controlTypes, dataTypes } from "../../common/constants";
import { strArrayToSelectOptions } from "../../utils/functions";
import useQuickscreen from "../../composables/useQuickscreen";
import useLanguage from "../../composables/useLanguage";

export default {
  components: {},
  setup() {
    const { language } = useLanguage();
    const {
      transition,
      script,
      currentQuestion,
      currentQuestionIndex,

      previousQuestion,
      nextQuestion,

      nextStep,
      lastStep,
    } = useQuickscreen();

    const answer = ref("unanswered");
    const isLoaded = ref(false);
    const loaded = () => {
      isLoaded.value = true;
    };
    const setAnswer = (a) => {
      answer.value = a;
    };

    return {
      language,
      transition,
      script,
      currentQuestion,
      controlTypes,
      dataTypes,
      strArrayToSelectOptions,

      previousQuestion,
      nextQuestion,
      currentQuestionIndex,
      isLoaded,
      loaded,
      nextStep,
      setAnswer,
      answer,
      lastStep,
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
