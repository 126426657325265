import { relationships } from "../common/constants";
import { assetCategories, expenseCategories } from "../common/constants";
import { defaultFacility, facilities, apiURLs } from "../common/facilities";

const findResident = (residentId, residents) => {
  const resident = residents.find(
    (resident) => resident.residentId === residentId
  );

  return resident;
};
const findQuestionRecursively = (questionGuid, questionsAnswers) =>
  questionsAnswers.reduce((a, question) => {
    if (a) return a;
    if (question.questionGuid.toUpperCase() === questionGuid.toUpperCase())
      return question;
    if (question.questionsAnswers.length)
      return findQuestionRecursively(questionGuid, question.questionsAnswers);
  }, null);
const getQuestionsRecursively = (level, questionsAnswers) => {
  let questions = [];
  level++;

  questionsAnswers.forEach((question) => {
    question.level = level;
    questions.push(question);
    if (question.questionsAnswers.length != 0) {
      questions = questions.concat(
        getQuestionsRecursively(level, question.questionsAnswers)
      );
    }
  });

  return questions;
};
const isSelf = (resident) => {
  return resident.relationship === relationships.self;
};
const getAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
const formatDOB = (dob) => {
  if (dob.includes("T")) {
    const dobArr = dob.split("-");
    const mm = dobArr[1];
    const dd = dobArr[2].substring(0, 2);
    const yyyy = dobArr[0];
    const formattedDOB = `${mm}/${dd}/${yyyy}`;
    return formattedDOB === "01/01/0001" ? "" : formattedDOB;
  }
  return dob;
};
const getGeneralAssets = (resident) => {
  let assets = [];
  resident.assets.generalAssets.forEach((generalAsset) => {
    assets.push({
      id: generalAsset.id,
      category: assetCategories.general,
      type: generalAsset.generalAssetType,
      residentId: resident.residentId,
      owner: resident.label,
      value: generalAsset.value,
      description: generalAsset.label,
    });
  });
  return assets;
};
const getFinancialAssets = (resident) => {
  let assets = [];
  resident.assets.financialAssets.forEach((financialAsset) => {
    assets.push({
      id: financialAsset.id,
      category: assetCategories.financial,
      type: financialAsset.financialAssetType,
      residentId: resident.residentId,
      owner: resident.label,
      value: financialAsset.value,
      description: financialAsset.label,
    });
  });
  return assets;
};
const getRealEstateAssets = (resident) => {
  let assets = [];
  resident.assets.realEstateAssets.forEach((realEstateAsset) => {
    assets.push({
      id: realEstateAsset.id,
      category: assetCategories.realEstate,
      type: realEstateAsset.realEstateAssetType,
      residentId: resident.residentId,
      owner: resident.label,
      value: realEstateAsset.value,
      description: realEstateAsset.label,
    });
  });
  return assets;
};
const getVehicleAssets = (resident) => {
  let assets = [];
  resident.assets.vehicleAssets.forEach((vehicleAsset) => {
    assets.push({
      id: vehicleAsset.id,
      category: assetCategories.vehicle,
      type: vehicleAsset.vehicleAssetType,
      residentId: resident.residentId,
      owner: resident.label,
      value: vehicleAsset.value,
      description: vehicleAsset.label,
    });
  });
  return assets;
};
const getInsuranceAssets = (resident) => {
  let assets = [];
  resident.assets.insuranceAssets.forEach((insuranceAsset) => {
    assets.push({
      id: insuranceAsset.id,
      category: assetCategories.insurance,
      type: insuranceAsset.insuranceAssetType,
      residentId: resident.residentId,
      owner: resident.label,
      value: insuranceAsset.value,
      description: insuranceAsset.label,
    });
  });
  return assets;
};
const getGeneralExpenses = (resident) => {
  let expenses = [];
  resident.deductions.generalDeductions.forEach((generalDeduction) => {
    expenses.push({
      id: generalDeduction.id,
      category: expenseCategories.general,
      type: generalDeduction.generalDeductionType,
      residentId: resident.residentId,
      owner: resident.label,
      cost: generalDeduction.cost,
      total: generalDeduction.total,
      frequency: generalDeduction.frequency,
      description: generalDeduction.label,
      startDate: generalDeduction.startDate,
      endDate: generalDeduction.endDate,
    });
  });
  return expenses;
};
const getMedicalExpenses = (resident) => {
  let expenses = [];
  resident.deductions.medicalExpenseDeductions.forEach((medicalDeduction) => {
    expenses.push({
      id: medicalDeduction.id,
      category: expenseCategories.medical,
      type: medicalDeduction.medicalExpenseDeductionType,
      residentId: resident.residentId,
      owner: resident.label,
      cost: medicalDeduction.cost,
      total: medicalDeduction.total,
      frequency: medicalDeduction.frequency,
      description: medicalDeduction.label,
      startDate: medicalDeduction.startDate,
      endDate: medicalDeduction.endDate,
    });
  });
  return expenses;
};
const getSupportExpenses = (resident) => {
  let expenses = [];
  resident.deductions.supportExpenseDeductions.forEach((supportDeduction) => {
    expenses.push({
      id: supportDeduction.id,
      category: expenseCategories.support,
      type: supportDeduction.supportExpenseDeductionType,
      residentId: resident.residentId,
      owner: resident.label,
      cost: supportDeduction.cost,
      total: supportDeduction.total,
      frequency: supportDeduction.frequency,
      description: supportDeduction.label,
      startDate: supportDeduction.startDate,
      endDate: supportDeduction.endDate,
    });
  });
  return expenses;
};
const getHousingExpenses = (resident) => {
  let expenses = [];
  resident.deductions.housingDeductions.forEach((housingDeduction) => {
    expenses.push({
      id: housingDeduction.id,
      category: expenseCategories.housing,
      type: housingDeduction.housingDeductionType,
      residentId: resident.residentId,
      owner: resident.label,
      cost: housingDeduction.cost,
      total: housingDeduction.total,
      frequency: housingDeduction.frequency,
      description: housingDeduction.label,
      startDate: housingDeduction.startDate,
      endDate: housingDeduction.endDate,
    });
  });
  return expenses;
};
const getUtilitiesExpenses = (resident) => {
  let expenses = [];
  resident.deductions.utilitiesDeductions.forEach((utilitiesDeduction) => {
    expenses.push({
      id: utilitiesDeduction.id,
      category: expenseCategories.utilities,
      type: utilitiesDeduction.utilitiesDeductionType,
      residentId: resident.residentId,
      owner: resident.label,
      cost: utilitiesDeduction.cost,
      total: utilitiesDeduction.total,
      frequency: utilitiesDeduction.frequency,
      description: utilitiesDeduction.label,
      startDate: utilitiesDeduction.startDate,
      endDate: utilitiesDeduction.endDate,
    });
  });
  return expenses;
};
const strArrayToSelectOptions = (strArray) => {
  return strArray.map((str) => {
    return { value: str, text: str };
  });
};
const getGoogleAnalyticsCode = () => {
  const hostname = window.location.hostname;
  if (
    hostname in facilities &&
    facilities[hostname].gaCode &&
    facilities[hostname].apiURL !== apiURLs.local &&
    facilities[hostname].apiURL !== apiURLs.test &&
    facilities[hostname].apiURL !== apiURLs.dev
  ) {
    return facilities[hostname].gaCode;
  } else return defaultFacility.gaCode;
};
const getAPIUrl = () => {
  const hostname = window.location.hostname;
  if (hostname in facilities && facilities[hostname].apiURL)
    return facilities[hostname].apiURL;
  else return defaultFacility.apiURL;
};
const getFacilityId = () => {
  const hostname = window.location.hostname;
  if (facilities[hostname].facilityId) return facilities[hostname].facilityId;
  else return defaultFacility.facilityId;
};
const newGuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export {
  findResident,
  findQuestionRecursively,
  getQuestionsRecursively,
  isSelf,
  getAge,
  formatDOB,
  getGeneralAssets,
  getFinancialAssets,
  getRealEstateAssets,
  getVehicleAssets,
  getInsuranceAssets,
  getGeneralExpenses,
  getMedicalExpenses,
  getSupportExpenses,
  getHousingExpenses,
  getUtilitiesExpenses,
  strArrayToSelectOptions,
  getGoogleAnalyticsCode,
  getAPIUrl,
  getFacilityId,
  newGuid,
};
