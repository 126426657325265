<template>
  <div class="w-full">
    <div class="px-2 sm:px-0">
      <p class="w-full text-left my-4 text-sm">
        {{ language.friendlyAdvocatiaRepContact }}
      </p>
      <FullContactForm />
      <EligiblePrograms />
    </div>
    <Actions @previous="previousStep" @next="startEnrollment" class="mt-4">
      <template #previous>
        <span>{{ language.back }}</span>
      </template>
      <template #next>
        <span>{{ language.startEnrollment }}</span>
      </template>
    </Actions>
  </div>
</template>

<script>
import useQuickscreen from "../../../../composables/useQuickscreen";
import useBenefitRecord from "../../../../composables/useBenefitRecord";
import useLanguage from "../../../../composables/useLanguage";
import useApp from "../../../../composables/useApp";
import Actions from "../../ui/Actions.vue";
import EligiblePrograms from "../../ui/EligiblePrograms.vue";
import FullContactForm from "../../ui/FullContactForm.vue";
export default {
  components: {
    EligiblePrograms,
    FullContactForm,
    Actions,
  },
  setup() {
    const { saveQuickscreen, previousStep, nextStep } = useQuickscreen();
    const { setLoading } = useApp();
    const { email, phone, saveBenefitRecord } = useBenefitRecord();
    const { language } = useLanguage();

    const startEnrollment = async () => {
      if (phone.value !== "" || email.value !== "") {
        setLoading(true);
        await saveQuickscreen();
        await saveBenefitRecord();
        setLoading(false);
        nextStep();
      }
    };

    return {
      startEnrollment,
      language,
      previousStep,
    };
  },
};
</script>

<style></style>
