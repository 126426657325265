<template>
  <div class="w-full text-center mt-8 text-sm pb-32" v-if="contact">
    <p>{{ language.ratherTalkToAdvisor }}</p>
    <p>{{ language.weAreHereToHelp }}</p>
    <p>
      <span v-if="contact.call">
        {{ language.callUsAt }}
        <a :href="`tel:${contact.call}`" class="text-primary hover:underline"
          >{{ contact.call }} </a
        >,
      </span>
      <span v-if="contact.text">
        &nbsp;{{ language.text }} {{ contact.textCode }} {{ language.to }}
        <a :href="`sms:${contact.text}`" class="text-primary hover:underline">{{
          contact.text
        }}</a
        >,
      </span>
      <span v-if="contact.website">
        {{ language.or }}
        <a
          :href="contact.website"
          target="_blank"
          class="text-primary hover:underline"
          >{{ language.visitUsHere }}</a
        >
      </span>
      <span>
        <button
          style="display: inline-block"
          class="mb-4 px-1 text-blue-500 font-medium text-sm flex justify-center items-center"
          @click="displayShowTermsModal"
        >
          {{ language.termsConditions }}
        </button>
      </span>
    </p>
    <div class="flex justify-center items-center">
      <a href="https://www.advocatia.io/" target="_blank"><img src="https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/advocatia_powered_by.png" class="h-12"/>
      </a>
    </div>

  </div>
  <TermsModal
    :showModal="getShowTermsModal()"
    @hideModal="hideShowTermsModal"
    @addAcknowledgement="
      hideShowTermsModal();
      acknowledgeTerms();
    "
  />
  
  
</template>

<script>
import useQuickscreen from "../../../composables/useQuickscreen";
import useLanguage from "../../../composables/useLanguage";
import TermsModal from "../../common/ui/TermsModal.vue";
import useTerms from "../../../composables/useTerms";
import { ref } from "vue";

export default {
  components: {
    //TextField,
    TermsModal,
  },
  setup() {
    const { language } = useLanguage();
    const { contact } = useQuickscreen();
    const { acknowledgeTerms } = useTerms();
    const showTermsModal = ref(false);
    const displayShowTermsModal = () => {
      showTermsModal.value = true;
    };
    const getShowTermsModal = () => {
      return showTermsModal.value;
    };
    const hideShowTermsModal = () => {
      showTermsModal.value = false;
    };
    return {
      language,
      contact,
      displayShowTermsModal,
      getShowTermsModal,
      hideShowTermsModal,
      acknowledgeTerms,
    };
  },
};
</script>

<style></style>
