<template>
  <div class="w-full">
    <router-link
      :to="to"
      :class="active ? 'bg-primary-light' : 'hover:bg-gray-100'"
      class="w-full h-12 flex justify-between items-center px-2 pl-4"
    >
      <div
        :class="active ? 'font-semibold text-primary-dark' : 'text-gray-500'"
        class="flex items-center"
      >
        <div class="flex justify-start items-center px-2 text-sm">
          <slot>Button Text</slot>
          <svg
            v-if="complete"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ai ai-DoubleCheck ml-1 text-green-500"
          >
            <path d="M2 12l5.25 5 2.625-3" />
            <path d="M8 12l5.25 5L22 7" />
            <path d="M16 7l-3.5 4" />
          </svg>
        </div>
      </div>
    </router-link>
    <transition name="slide-down" v-if="subMenus.length && active" appear>
      <div class="w-full">
        <router-link
          v-for="subMenu in subMenus"
          :to="subMenu.routeName"
          :key="subMenu.routeName"
          :class="
            (!$route.params.id && $route.name === subMenu.routeName.name) ||
            subMenuActive(subMenu.routeName)
              ? 'bg-gray-200'
              : 'hover:bg-gray-100'
          "
          class="w-full h-8 flex justify-between items-center px-2 pl-8 text-xs"
        >
          {{ subMenu.name }}
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";
export default {
  props: {
    complete: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: Object,
      required: true,
    },
    subMenus: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const route = useRoute();

    const active = computed(() => {
      return (
        route.name === props.to.name ||
        props.subMenus.filter(
          (subMenu) => subMenu.routeName.name === route.name
        ).length
      );
    });

    const subMenuActive = (routeName) => {
      if (route.params.id && routeName.params && routeName.params.id) {
        return `${route.params.id}` === `${routeName.params.id}`;
      }
    };

    return {
      active,
      subMenuActive,
    };
  },
};
</script>
