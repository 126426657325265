const relationships = {
  self: "Self",
  spouse: "Spouse",
  significantOther: "Significant Other",
  parent: "Parent",
  child: "Child",
  stepparent: "Stepparent",
  stepchild: "Stepchild",
  sibling: "Sibling",
  grandparent: "Grandparent",
  grandchild: "Grandchild",
  auntUncle: "Aunt/Uncle",
  nieceNephew: "Niece/Nephew",
  cousin: "Cousin",
  other: "Other",
};
const relationshipOptions = [
  { text: relationships.self, value: relationships.self },
  { text: relationships.spouse, value: relationships.spouse },
  {
    text: relationships.significantOther,
    value: relationships.significantOther,
  },
  { text: relationships.parent, value: relationships.parent },
  { text: relationships.child, value: relationships.child },
  { text: relationships.stepparent, value: relationships.stepparent },
  { text: relationships.stepchild, value: relationships.stepchild },
  { text: relationships.sibling, value: relationships.sibling },
  { text: relationships.grandparent, value: relationships.grandparent },
  { text: relationships.grandchild, value: relationships.grandchild },
  { text: relationships.auntUncle, value: relationships.auntUncle },
  { text: relationships.nieceNephew, value: relationships.nieceNephew },
  { text: relationships.cousin, value: relationships.cousin },
  { text: relationships.other, value: relationships.other },
];
const stateOptions = [
  { value: "AL", text: "Alabama" },
  { value: "AK", text: "Alaska" },
  { value: "AZ", text: "Arizona" },
  { value: "AR", text: "Arkansas" },
  { value: "CA", text: "California" },
  { value: "CO", text: "Colorado" },
  { value: "CT", text: "Connecticut" },
  { value: "DE", text: "Delaware" },
  { value: "DC", text: "District Of Columbia" },
  { value: "FL", text: "Florida" },
  { value: "GA", text: "Georgia" },
  { value: "HI", text: "Hawaii" },
  { value: "ID", text: "Idaho" },
  { value: "IL", text: "Illinois" },
  { value: "IN", text: "Indiana" },
  { value: "IA", text: "Iowa" },
  { value: "KS", text: "Kansas" },
  { value: "KY", text: "Kentucky" },
  { value: "LA", text: "Louisiana" },
  { value: "ME", text: "Maine" },
  { value: "MD", text: "Maryland" },
  { value: "MA", text: "Massachusetts" },
  { value: "MI", text: "Michigan" },
  { value: "MN", text: "Minnesota" },
  { value: "MS", text: "Mississippi" },
  { value: "MO", text: "Missouri" },
  { value: "MT", text: "Montana" },
  { value: "NE", text: "Nebraska" },
  { value: "NV", text: "Nevada" },
  { value: "NH", text: "New Hampshire" },
  { value: "NJ", text: "New Jersey" },
  { value: "NM", text: "New Mexico" },
  { value: "NY", text: "New York" },
  { value: "NC", text: "North Carolina" },
  { value: "ND", text: "North Dakota" },
  { value: "OH", text: "Ohio" },
  { value: "OK", text: "Oklahoma" },
  { value: "OR", text: "Oregon" },
  { value: "PA", text: "Pennsylvania" },
  { value: "RI", text: "Rhode Island" },
  { value: "SC", text: "South Carolina" },
  { value: "SD", text: "South Dakota" },
  { value: "TN", text: "Tennessee" },
  { value: "TX", text: "Texas" },
  { value: "UT", text: "Utah" },
  { value: "VT", text: "Vermont" },
  { value: "VA", text: "Virginia" },
  { value: "WA", text: "Washington" },
  { value: "WV", text: "West Virginia" },
  { value: "WI", text: "Wisconsin" },
  { value: "WY", text: "Wyoming" },
];
const sexQuestionGuid = "53589E2B-6C99-4D83-834C-569B58079806";
const emptyGuid = "00000000-0000-0000-0000-000000000000";
const controlTypes = {
  dropdown: "Dropdown",
  textbox: "TextBox",
  householdDropdown: "HouseholdDropdown",
  raceDropdown: "RaceDropdown",
  multiSelect: "MultiSelect",
};
const assetCategories = {
  general: "General",
  financial: "Financial",
  realEstate: "Real Estate",
  vehicle: "Vehicle",
  insurance: "Insurance",
};
const assetCategoryOptions = [
  { value: assetCategories.general, text: assetCategories.general },
  { value: assetCategories.financial, text: assetCategories.financial },
  { value: assetCategories.realEstate, text: assetCategories.realEstate },
  { value: assetCategories.vehicle, text: assetCategories.vehicle },
  { value: assetCategories.insurance, text: assetCategories.insurance },
];
const assetTypes = {
  General: [
    {
      value: "BUSINESSEQUIPMENT",
      text: "Business/Farm Equipment",
    },
    {
      value: "CASH",
      text: "Cash",
    },
    {
      value: "HEALTHSAVINGSACCOUNT",
      text: "Health Savings Account",
    },
    {
      value: "MINERALOROILRIGHTS",
      text: "Mineral or Oil Rights",
    },
    {
      value: "NURSINGHOMEACCOUNTS",
      text: "Nursing Home Account",
    },
    {
      value: "TAXSHELTER",
      text: "Tax Shelter Account",
    },
    {
      value: "OTHER",
      text: "Other",
    },
  ],
  Financial: [
    {
      value: "FOURONEK",
      text: "401K",
    },
    {
      value: "ANNUITY",
      text: "Annuity",
    },
    {
      value: "BANKACCOUNT",
      text: "Bank Account",
    },
    {
      value: "BONDS",
      text: "Bonds",
    },
    {
      value: "CERTIFICATEOFDEPOSIT",
      text: "Certificate of Deposit",
    },
    {
      value: "CHECKINGACCOUNT",
      text: "Checking Account",
    },
    {
      value: "CHRISTMASCLUB",
      text: "Christmas Club",
    },
    {
      value: "CREDITUNION",
      text: "Credit Union",
    },
    {
      value: "IRAORKEOUGHACCOUNT",
      text: "IRA",
    },
    {
      value: "KEOGHPLAN",
      text: "Keogh Plan",
    },
    {
      value: "MONEYMARKETFUNDS",
      text: "Money Market Fund",
    },
    {
      value: "MUTUALFUNDS",
      text: "Mutual Funds",
    },
    {
      value: "PENSIONORRETIREMENTFUND",
      text: "Pension or Retirement Account",
    },
    {
      value: "PROMISSORYNOTES",
      text: "Promissory Note",
    },
    {
      value: "SAFEDEPOSITBOX",
      text: "Safe Deposit Box",
    },
    {
      value: "SAVINGSACCOUNT",
      text: "Savings Account",
    },
    {
      value: "STOCKS",
      text: "Stocks",
    },
    {
      value: "TRUSTFUND",
      text: "Trust Fund",
    },
    {
      value: "OTHERACCOUNT",
      text: "Other Financial Asset",
    },
  ],
  "Real Estate": [
    {
      value: "BURIALPLANIRREVOCABLE",
      text: "Burial Plan Irrevocable",
    },
    { value: "BURIALPLANREVOCABLE", text: "Burial Plan Revocable" },
    { value: "BURIALPLOT", text: "Burial Plot" },
    { value: "INCOMEPRODUCINGFARM", text: "Farm" },
    { value: "LAND", text: "Land" },
    { value: "LIFEESTATE", text: "Life Estate" },
    { value: "HOUSEHOMESTEAD", text: "Primary Home or Mortgage" },
    { value: "RENTALPROPERTY", text: "Rental Property" },
    { value: "REVERSEMORTGAGES", text: "Reverse Mortgage" },
    { value: "REALESTATE", text: "Other Property" },
  ],
  Vehicle: [
    { value: "BOAT", text: "Boat" },
    { value: "MOBILEHOME", text: "Mobile Home" },
    { value: "PRIMARYVEHICLE", text: "Primary Vehicle" },
    { value: "OTHERVEHICLE", text: "Other Vehicle" },
  ],
  Insurance: [
    { value: "LIFEINSURANCE", text: "Life Insurance" },
    { value: "TERMLIFEINSURANCE", text: "Term Life Insurance" },
    { value: "WHOLELIFEINSURANCE", text: "Whole Life Insurance" },
  ],
};
const expenseCategories = {
  general: "General",
  medical: "Medical",
  support: "Support",
  housing: "Housing",
  utilities: "Utilities",
};
const expenseCategoryOptions = [
  { value: expenseCategories.general, text: expenseCategories.general },
  { value: expenseCategories.medical, text: expenseCategories.medical },
  { value: expenseCategories.support, text: expenseCategories.support },
  { value: expenseCategories.housing, text: expenseCategories.housing },
  { value: expenseCategories.utilities, text: expenseCategories.utilities },
];
const expenseTypes = {
  General: [
    { value: "AUTOINSURANCEPREMIUM", text: "Auto Insurance Premium" },
    { value: "CARPAYMENT", text: "Car Payment" },
    { value: "EXCLUDEDBUSINESSEXPENSES", text: "Excluded Business Expenses" },
    { value: "EARLYWITHDRAWPENALTY", text: "Early Withdraw Penalty" },
    { value: "EDUCATOREXPENSES", text: "Educator Expenses" },
    { value: "SCHOLARSHIPAWARDGRANT", text: "Scholarship Award Grant" },
    { value: "IRADEDUCTION", text: "IRA Education" },
    { value: "MOVINGEXPENSES", text: "Moving Expenses" },
    { value: "SELFEMPLOYMENTTAX", text: "Self Employment" },
    {
      value: "EXCLUDEDSELFEMPLOYEDEXPENSES",
      text: "Excluded Self Employed Expenses",
    },
    { value: "STUDENTLOANINTEREST", text: "Student Loan Interest" },
    { value: "OTHER", text: "Other" },
  ],
  Medical: [
    { value: "HEALTHINSURANCEPREMIUMS", text: "Health Insurance Premiums" },
    { value: "HSADEDUCTION", text: "HSA Deduction" },
    { value: "MEDICALEQUIPMENT", text: "Medical Equipment" },
    { value: "MEDICALEXPENSE", text: "Medical Expense" },
    { value: "MEDICALTRANSPORT", text: "Medical Transport" },
    { value: "MEDICAREPARTA", text: "Medicare Part A" },
    { value: "MEDICAREPARTB", text: "Medicare Part B" },
    { value: "MEDICINE", text: "Medicine" },
    { value: "NURSINGFACILITYCARE", text: "Nursing Facility Care" },
  ],
  Support: [
    { value: "ALIMONY", text: "Alimony" },
    { value: "CHILDCARE", text: "Childcare" },
    { value: "CHILDSUPPORT", text: "Child Support" },
  ],
  Housing: [
    { value: "GROCERIES", text: "Groceries" },
    { value: "HOMEOWNERSINS", text: "Homeowner Insurance" },
    { value: "MORTGAGE", text: "Mortgage" },
    { value: "PROPERTYTAX", text: "Property Tax" },
    { value: "RENT", text: "Rent" },
    { value: "ROOMANDBOARD", text: "Room and Board" },
    { value: "SHELTEREXPENSES", text: "Shelter Expenses" },
  ],
  Utilities: [
    { value: "CABLEORSATELLITE", text: "Cable or Satellite" },
    { value: "ELECTRICITY", text: "Electricity" },
    { value: "GAS", text: "Gas" },
    { value: "OTHERORLIHEAP", text: "Other or LIHEAP" },
    { value: "TELEPHONE", text: "Telephone" },
    { value: "TRASHREMOVAL", text: "Trash Removal" },
    { value: "WATERORSEWER", text: "Water or Sewer" },
  ],
};
const questionScopes = {
  screening: "SCREENING",
  questionnaire: "QUESTIONNAIRE",
};
const eligibilities = {
  eligible: "Eligible",
  inelibile: "Ineligible",
  undetermined: "Undetermined",
};
const guidFormat = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const componentTypes = {
  question: 0,
  contact: 1,
};
const languages = {
  en: "English",
  es: "Spanish",
};
const dataTypes = {
  string: "System.String",
  number: "System.Int32",
  date: "System.DateTime",
};
const formFieldTypes = {
  pushButton: 0,
  checkbox: 1,
  radio: 2,
  text: 3,
  list: 4,
  combo: 5,
  signature: 6,
};

export {
  relationships,
  relationshipOptions,
  stateOptions,
  emptyGuid,
  sexQuestionGuid,
  controlTypes,
  assetCategories,
  assetCategoryOptions,
  assetTypes,
  expenseCategories,
  expenseCategoryOptions,
  expenseTypes,
  questionScopes,
  eligibilities,
  guidFormat,
  componentTypes,
  languages,
  dataTypes,
  formFieldTypes,
};
