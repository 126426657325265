<template>
  <div class="w-full relative" v-show="isLoaded">
    <img
      @load="loaded"
      src="https://quickscreen-assets.s3.us-east-2.amazonaws.com/img/results-page_transparent_opacity.png"
      alt=""
    />
    <div
      class="absolute top-0 left-0 right-0 bottom-0 text-center flex justify-end"
    >
      <p
        class="w-full text-white font-bold text-3xl sm:text-5xl p-2 pt-12 font-secondary"
      >
        {{ language.yourResults }}
      </p>
    </div>
  </div>
</template>

<script>
import useLanguage from "../../../composables/useLanguage";
import { ref } from "vue";
export default {
  setup() {
    const { language } = useLanguage();
    const isLoaded = ref(false);
    const loaded = () => {
      isLoaded.value = true;
    };

    return {
      language,
      isLoaded,
      loaded,
    };
  },
};
</script>
