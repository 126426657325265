import { createRouter, createWebHistory } from "vue-router";
import useAuth from "../composables/useAuth";

const { isAuthenticated, loadAuth } = useAuth();

const routes = [
  {
    path: "/",
    redirect: "/screen",
  },
  {
    path: "/quickscreen",
    redirect: "/screen",
  },
  {
    path: "/screen",
    name: "screen",
    component: () => import("../views/screen/Index.vue"),
    children: [
      {
        path: "/screen/questions",
        name: "screen-questions",
        component: () => import("../views/screen/Questions.vue"),
      },
      {
        path: "/screen/contact",
        name: "screen-contact",
        component: () => import("../views/screen/Contact.vue"),
      },
      //screen-additional
      {
        path: "/screen/additional",
        name: "screen-additional",
        component: () =>
          import("../views/screen/ConfirmAdditionalQuestions.vue"),
      },
      {
        path: "/screen/assign-advocate",
        name: "screen-assign-advocate",
        component: () => import("../views/screen/AssignAdvocate.vue"),
      },
      {
        path: "/screen/additional-notes",
        name: "screen-additional-notes",
        component: () => import("../views/screen/AdditionalNotes.vue"),
      },
      {
        path: "/screen/thank-you",
        name: "screen-thank-you",
        component: () => import("../views/screen/ThankYou.vue"),
      },
      {
        path: "/screen/terms",
        name: "screen-terms",
        component: () => import("../views/screen/Terms.vue"),
      },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/home/Dashboard.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/details",
    name: "details",
    component: () => import("../views/home/Details.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/home/Contact.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/address",
    name: "address",
    component: () => import("../views/home/Address.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/interview",
    name: "interview",
    component: () => import("../views/home/Interview.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/resident-interview/:id",
    name: "resident-interview",
    component: () => import("../views/home/ResidentInterview.vue"),
    props: { id: "" },
    meta: {
      authorize: true,
    },
  },
  {
    path: "/forms",
    name: "forms",
    component: () => import("../views/home/Forms.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/household",
    name: "household",
    component: () => import("../views/home/Household.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/income",
    name: "income",
    component: () => import("../views/home/Income.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/assets",
    name: "assets",
    component: () => import("../views/home/Assets.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/expenses",
    name: "expenses",
    component: () => import("../views/home/Expenses.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/user/Login.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/user/Logout.vue"),
  },
  {
    path: "/my-screenings",
    name: "my-screenings",
    component: () => import("../views/user/MyScreenings.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/home/Search.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/triage",
    name: "triage",
    component: () => import("../views/triage/Index.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 };
  },
  routes,
});

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
  if (to.meta.authorize) {
    await loadAuth();
    if (!isAuthenticated.value) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
