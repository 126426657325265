<template>
  <Modal :showModal="showModal">
    <div
      class="w-full max-w-md rounded relative bg-white shadow"
      v-click-away="hideModal"
    >
      <div
        class="w-full bg-gray-100 flex justify-between items-center p-3 rounded-t"
      >
        <div>
          <h1 class="text-sm uppercase text-gray-700 font-semibold text-sm">
            Add Household Member
          </h1>
        </div>
        <button
          @click="hideModal"
          class="hover:bg-gray-200 w-6 h-6 rounded flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ai ai-Cross"
          >
            <path d="M20 20L4 4m16 0L4 20" />
          </svg>
        </button>
      </div>
      <div class="p-4">
        <div class="grid grid-cols-1 gap-y-4">
          <Select
            :label="'Relationship'"
            :placeholder="'Select relationship'"
            :options="relationshipOptions"
            :modelValue="relationship"
            :excludeOptions="[relationships.self]"
            @select="setRelationship"
            labelClasses="font-medium text-xs mb-2"
            selectClasses="bg-gray-50 py-2 pl-4 pr-2 border border-gray-200 rounded flex justify-between items-center text-sm"
            focusClasses="bg-gray-50 border border-primary rounded"
          />
          <TextField
            label="First name"
            placeholder="Enter first name"
            :modelValue="firstName"
            @textChange="setFirstName"
            labelClasses="font-medium text-xs mb-2"
            inputClasses="bg-gray-50 py-2 px-4 border border-gray-200 rounded text-sm"
            focusClasses="bg-gray-50 py-2 px-4 border border-primary rounded text-sm"
          />
          <TextField
            label="Last name"
            placeholder="Enter last name"
            :modelValue="lastName"
            @textChange="setLastName"
            labelClasses="font-medium text-xs mb-2"
            inputClasses="bg-gray-50 py-2 px-4 border border-gray-200 rounded text-sm"
            focusClasses="bg-gray-50 py-2 px-4 border border-primary rounded text-sm"
          />
          <Select
            :label="'Sex'"
            :placeholder="'Select sex'"
            :options="[
              { value: 'Male', text: 'Male' },
              { value: 'Female', text: 'Female' },
            ]"
            :modelValue="sex"
            @select="setSex"
            labelClasses="font-medium text-xs mb-2"
            selectClasses="bg-gray-50 py-2 pl-4 pr-2 border border-gray-200 rounded flex justify-between items-center text-sm"
            focusClasses="bg-gray-50 border border-primary rounded"
          />
          <TextField
            label="Birthday"
            placeholder="MM/DD/YYYY"
            :modelValue="dob"
            @textChange="setDOB"
            :mask="'##/##/####'"
            :pattern="'[0-9]*'"
            labelClasses="font-medium text-xs mb-2"
            inputClasses="bg-gray-50 py-2 px-4 border border-gray-200 rounded text-sm"
            focusClasses="bg-gray-50 py-2 px-4 border border-primary rounded text-sm"
          />
          <TextField
            label="SSN"
            placeholder="Enter SSN"
            :modelValue="ssn"
            @textChange="setSSN"
            :mask="'###-##-####'"
            :pattern="'[0-9]*'"
            labelClasses="font-medium text-xs mb-2"
            inputClasses="bg-gray-50 py-2 px-4 border border-gray-200 rounded text-sm"
            focusClasses="bg-gray-50 py-2 px-4 border border-primary rounded text-sm"
          />
        </div>
      </div>
      <div class="p-3 flex justify-end items-center rounded-b">
        <button
          @click="hideModal"
          class="py-2 px-4 text-sm bg-gray-200 rounded border border-gray-200 hover:bg-gray-300 hover:border-gray-300 mr-1"
        >
          Cancel
        </button>
        <button
          @click="addHouseholdMember"
          class="py-2 px-4 text-sm btn-primary rounded border"
        >
          Add Member
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { relationships, relationshipOptions } from "../../../common/constants";
import { newGuid } from "../../../utils/functions";
import useBenefitRecord from "../../../composables/useBenefitRecord";
import Modal from "../../common/ui/Modal.vue";
import TextField from "../../common/ui/TextField.vue";
import Select from "../../common/ui/Select.vue";
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["hideModal"],
  components: {
    Modal,
    TextField,
    Select,
  },
  setup(_, { emit }) {
    const { addResident, saveBenefitRecord } = useBenefitRecord();

    const state = reactive({
      relationship: "",
      firstName: "",
      lastName: "",
      sex: "",
      dob: "",
      ssn: "",
    });

    const setRelationship = (relationship) => {
      state.relationship = relationship;
    };

    const setFirstName = (firstName) => {
      state.firstName = firstName;
    };

    const setLastName = (lastName) => {
      state.lastName = lastName;
    };

    const setSex = (sex) => {
      state.sex = sex;
    };

    const setDOB = (dob) => {
      state.dob = dob;
    };

    const setSSN = (ssn) => {
      state.ssn = ssn;
    };

    const hideModal = () => {
      Object.assign(state, {
        relationship: "",
        firstName: "",
        lastName: "",
        sex: "",
        dob: "",
        ssn: "",
      });
      emit("hideModal");
    };

    const addHouseholdMember = async () => {
      const residentId = newGuid();
      const resident = {
        residentId,
        relationship: state.relationship,
        firstName: state.firstName,
        lastName: state.lastName,
        sex: state.sex,
        dob: state.dob,
        ssn: state.ssn,
      };
      addResident(resident);
      await saveBenefitRecord();
      emit("hideModal");
    };

    return {
      relationships,
      relationshipOptions,
      ...toRefs(state),
      hideModal,
      setRelationship,
      setFirstName,
      setLastName,
      setSex,
      setDOB,
      setSSN,
      addHouseholdMember,
    };
  },
};
</script>

<style></style>
