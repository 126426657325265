<template>
  <div class="w-full">
    <div v-for="question in script" :key="question.questionGuid">
      <transition
        :name="`slide-${transition}`"
        appear
        v-if="currentQuestion === question.questionGuid"
      >
        <div class="w-full bg-white">
          <div class="w-full my-4 px-2 sm:px-0">
            <QuestionHeader />
            <h2
              class="font-bold w-full text-left font-secondary"
              :class="questionTextClass(question.questionText)"
            >
              {{ question.questionText }}

            </h2>

            <div class="my-4 w-full">
              <Dropdown
                v-if="
                  question.controlType === controlTypes.dropdown &&
                  question.availableAnswers.length < 5
                "
                :questionGuid="question.questionGuid"
                :availableAnswers="question.availableAnswers"
                :answer="question.answer"
              />
              <Select
                v-else-if="
                  question.controlType === controlTypes.dropdown &&
                  question.availableAnswers.length >= 5
                "
                :label="''"
                :placeholder="language.selectAnOption"
                :wrapperClasses="'my-3'"
                :selectClasses="'bg-white rounded-md px-4 py-3 border shadow-sm flex justify-between items-center hover:bg-gray-100'"
                :focusClasses="'border-secondary bg-secondary-light'"
                :modelValue="question.answer"
                :options="strArrayToSelectOptions(question.availableAnswers)"
                @select="selectAnswer"
              />
              <VueMultiselect
                v-else-if="question.controlType === controlTypes.multiSelect"
                v-model="multiSelectMap[currentQuestion]"
                :options="question.availableAnswers"
                :multiple="true"
                :close-on-select="true"
                placeholder="Pick some"
                @select="answerMultiSelect"
                @remove="filterMultiSelect"
              />
              <TextField
                v-else-if="
                  question.controlType === controlTypes.textbox &&
                  question.dataType === dataTypes.number
                "
                :label="''"
                :placeholder="language.enterNumber"
                :modelValue="question.answer"
                @textChange="enterText"
                :pattern="'[0-9]*'"
                :inputClasses="'bg-white rounded shadow-sm px-4 py-3 border-1'"
                :focusClasses="'rounded shadow-sm px-4 py-3 border-1 border-secondary bg-secondary-light'"
              />
              <TextField
                v-else-if="question.controlType === controlTypes.textbox"
                :label="''"
                :placeholder="''"
                :modelValue="question.answer"
                @textChange="enterText"
                :inputClasses="'bg-white rounded shadow-sm px-4 py-3 border-1'"
                :focusClasses="'rounded shadow-sm px-4 py-3 border-1 border-secondary bg-secondary-light'"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
    <Actions
      @previous="previousQuestion"
      @next="nextQuestion"
      :showPreviousBtn="!isFirstQuestion()"
    >
      <template #previous>
        <span>{{ language.back }}</span>
      </template>
      <template #next>
        <span v-if="currentQuestionIndex === 0">{{ language.getStarted }}</span>
        <span v-else>{{ language.next }}</span>
      </template>
    </Actions>
  </div>
</template>

<script>
import { nextTick, onBeforeUnmount, reactive } from "@vue/runtime-core";

import { controlTypes, dataTypes } from "../../common/constants";
import { strArrayToSelectOptions } from "../../utils/functions";
import useQuickscreen from "../../composables/useQuickscreen";
import QuestionHeader from "../../components/screen/ui/QuestionHeader.vue";
import Dropdown from "../../components/screen/ui/Dropdown.vue";
import TextField from "../../components/common/ui/TextField.vue";
import Select from "../../components/common/ui/Select.vue";
import Actions from "../../components/screen/ui/Actions.vue";
import useLanguage from "../../composables/useLanguage";
import VueMultiselect from "vue-multiselect";
import { onMounted } from "vue";
import useBenefitRecord from "../../composables/useBenefitRecord";

export default {
  components: {
    QuestionHeader,
    Dropdown,
    TextField,
    Select,
    Actions,
    VueMultiselect,
  },
  setup() {
    const { language } = useLanguage();
    const {
      transition,
      script,
      currentQuestion,
      currentQuestionIndex,
      answerQuestion,
      answerAndProceed,
      previousQuestion,
      nextQuestion,
      questionListIndex,
    } = useQuickscreen();
    const { saveBenefitRecord } = useBenefitRecord();
    const selectAnswer = (answer) => {
      answerAndProceed(currentQuestion.value, answer);
    };

    onBeforeUnmount(async () => {
      const hasAdditionalQuestions =
        script.value.filter((q) => q.listIndex > 0).length > 0;
      console.log("has additonal questions: " + hasAdditionalQuestions);
      if (
        !script.value.filter((q) => q.answer === "").length &&
        hasAdditionalQuestions
      ) {
        console.log("SAVING, QUESTIONS ANSWERED");
        await saveBenefitRecord();
      } else {
        console.log("not saving. Need to answer all questions first.");
      }
    });
    const isFirstQuestion = () => {
      return (
        script.value
          .filter((q) => q.listIndex === questionListIndex.value)
          .findIndex((q) => q.questionGuid === currentQuestion.value) === 0
      );
    };
    var multiSelectMap = reactive({});
    var multiSelectAnswers = reactive([]);
    const enterText = (answer) => {
      answerQuestion(currentQuestion.value, answer);
    };

    const logAnswers = () => {
      console.log("logging answers!: " + multiSelectAnswers.value);
    };

    onMounted(() => {
      console.log("SCRIPT VALUE: " + script.value);

      for (var i = 0; i < script.value.length; i++) {
        const question = script.value[i];
        multiSelectMap[question.questionGuid] = question.answer
          .split("|")
          .filter((a) => a !== "");
      }
      
    });

    const filterMultiSelect = async (answer) => {
      await nextTick();
      if (multiSelectMap[currentQuestion.value].find((a) => a === answer)) {
        multiSelectMap[currentQuestion.value] = multiSelectMap[
          currentQuestion.value
        ].filter((a) => a !== answer);
      }
      answerQuestion(
        currentQuestion.value,
        multiSelectMap[currentQuestion.value]
          .filter((a) => a !== "")
          .join(" | ")
      );
    };
    const answerMultiSelect = async (answer) => {
      await nextTick();
      if (!multiSelectMap[currentQuestion.value].find((a) => a === answer)) {
        multiSelectMap[currentQuestion.value].push(answer);
      }

      answerQuestion(
        currentQuestion.value,
        multiSelectMap[currentQuestion.value]
          .filter((a) => a !== "")
          .join(" | ")
      );
    };
    const clearMultiSelect = () => {
      console.log("before clear multi: " + multiSelectAnswers);
      multiSelectAnswers = [];
      console.log("after clear multi: " + multiSelectAnswers);
    };
    const hasTooltip = (question) => {
      const hasTooltip = !(!question.tooltip || question.tooltip.length === 0);
      return hasTooltip;
    };
    const questionTextClass = (questionText) => {
      if (questionText.length < 100) return ["text-xl", "sm:text-2xl"];
      else return ["text-sm", "sm:text-lg"];
    };

    return {
      language,
      transition,
      script,
      currentQuestion,
      controlTypes,
      dataTypes,
      strArrayToSelectOptions,
      selectAnswer,
      enterText,
      previousQuestion,
      nextQuestion,
      currentQuestionIndex,
      questionTextClass,
      hasTooltip,
      multiSelectAnswers,
      logAnswers,
      answerMultiSelect,
      filterMultiSelect,
      clearMultiSelect,
      multiSelectMap,
      questionListIndex,
      isFirstQuestion,
      // options,
      // // options,
      // value,
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
