<template>
  <div>
    Successfully logged out
    <router-link class="text-blue-500 hover:underline" :to="{ name: 'login' }"
      >Login again</router-link
    >
  </div>
</template>

<script>
export default {};
</script>

<style></style>
