<template>
  <div class="w-full relative">
    <img
      src="https://quickscreen-assets.s3.us-east-2.amazonaws.com/img/landing-page_transparent_opacity.png"
      alt=""
    />
    <div
      class="absolute top-0 left-0 right-0 bottom-0 text-right flex justify-end"
    >
      <p
        class="w-3/4 text-white font-bold text-xl sm:text-3xl p-2 font-secondary"
      >
        {{ language.welcomeBenefitsProgramScreener }}
      </p>
    </div>
  </div>
</template>

<script>
import useLanguage from "../../../composables/useLanguage";

export default {
  setup() {
    const { language } = useLanguage();

    return {
      language,
    };
  },
};
</script>
