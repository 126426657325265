import { reactive, toRefs, computed, ref } from "vue";
import {
  assetCategories,
  emptyGuid,
  sexQuestionGuid,
} from "../common/constants";
import {
  formatDOB,
  findQuestionRecursively,
  getGeneralAssets,
  getFinancialAssets,
  getInsuranceAssets,
  getVehicleAssets,
  getRealEstateAssets,
  newGuid,
  getGeneralExpenses,
  getMedicalExpenses,
  getSupportExpenses,
  getHousingExpenses,
  getUtilitiesExpenses,
} from "../utils/functions";
import benefitScreeningAPI from "../services/benefitScreeningAPI";
import useLanguage from "./useLanguage";

const { setLanguage } = useLanguage();

const savingBenefitRecord = ref(false);
const state = reactive({
  benefitRecordId: "00000000-0000-0000-0000-000000000000",
  state: "IL",
  organizationId: "dbaa23f3-6367-44e8-83cd-f10755b2b40a",
  facilityId: "f939bc82-e7d6-4c0e-9402-aa8fdabbcad1",
  languageCode: "en",
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "0001-01-01T00:00:00",
  sex: "",
  ssn: "",
  email: "",
  phone: "",
  residentsCount: 1,
  totalIncome: 0.0,
  workflow: "",
  status: "",
  loginAttempts: "",
  channel: "Quickscreen 2.0",
  primaryResidence: {
    primaryResidenceId: "00000000-0000-0000-0000-000000000000",
    address: {
      addressId: "00000000-0000-0000-0000-000000000000",
      address1: "",
      address2: "",
      city: "",
      state: "IL",
      county: "",
      zipCode: "",
    },
    residents: [
      {
        residentId: "00000000-0000-0000-0000-000000000000",
        livesInResidence: true,
        label: "Self",
        index: 0,
        firstName: "",
        middleName: "",
        lastName: "",
        suffix: "",
        age: null,
        sex: "",
        ssn: "",
        dob: "0001-01-01T00:00:00",
        maritalStatus: null,
        relationship: "Self",
        phone: "",
        email: "",
        mailingAddress: {
          addressId: "00000000-0000-0000-0000-000000000000",
          address1: "",
          address2: "",
          city: "",
          state: "",
          county: "",
          zipCode: "",
        },
        assets: {
          generalAssets: [],
          financialAssets: [],
          realEstateAssets: [],
          vehicleAssets: [],
          insuranceAssets: [],
        },
        deductions: {
          generalDeductions: [],
          medicalExpenseDeductions: [],
          supportExpenseDeductions: [],
          housingDeductions: [],
          utilitiesDeductions: [],
        },
        earnedIncome: {
          earnedIncome: [],
        },
        unearnedIncome: {
          generalUnearnedIncomes: [],
          governmentBenefitsUnearnedIncomes: [],
          supportUnearnedIncomes: [],
          financialUnearnedIncomes: [],
          housingUnearnedIncomes: [],
        },
        questionsAnswers: [],
      },
    ],
    questionsAnswers: [],
    messages: [],
  },
  notes: [],
  forms: [],
});

//#region GETTERS
const selectedForms = computed(() => {
  return state.forms.filter((form) => form.selected);
});

const formattedDOB = computed(() => {
  return formatDOB(state.dob);
});

const address1 = computed(() => {
  return state.primaryResidence.address.address1;
});

const address2 = computed(() => {
  return state.primaryResidence.address.address2;
});

const city = computed(() => {
  return state.primaryResidence.address.city;
});

const residencyState = computed(() => {
  return state.primaryResidence.address.state;
});

const zipCode = computed(() => {
  return state.primaryResidence.address.zipCode;
});

const residents = computed(() => {
  return state.primaryResidence.residents;
});

const primaryResident = computed(() => {
  return state.primaryResidence.residents[0];
});

const loginAttempts = computed(() => {
  return state.loginAttempts;
});

const assets = computed(() => {
  let assets = [];
  state.primaryResidence.residents.forEach((resident) => {
    assets = assets.concat(getGeneralAssets(resident));
    assets = assets.concat(getFinancialAssets(resident));
    assets = assets.concat(getRealEstateAssets(resident));
    assets = assets.concat(getVehicleAssets(resident));
    assets = assets.concat(getInsuranceAssets(resident));
  });
  return assets;
});

const expenses = computed(() => {
  let expenses = [];
  state.primaryResidence.residents.forEach((resident) => {
    expenses = expenses.concat(getGeneralExpenses(resident));
    expenses = expenses.concat(getMedicalExpenses(resident));
    expenses = expenses.concat(getSupportExpenses(resident));
    expenses = expenses.concat(getHousingExpenses(resident));
    expenses = expenses.concat(getUtilitiesExpenses(resident));
  });
  return expenses;
});

const residentOptions = computed(() => {
  const options = [];
  residents.value.forEach((resident) => {
    options.push({ text: resident.label, value: resident.residentId });
  });

  return options;
});
//#endregion GETTERS

//#region ACTIONS
const loadBenefitRecordId = () => {
  const benefitRecordId = localStorage.getItem("benefitRecordId");
  if (benefitRecordId) state.benefitRecordId = benefitRecordId;
};

const loadBenefitRecord = async () => {
  try {
    const benefitRecordId = state.benefitRecordId;
    if (benefitRecordId !== emptyGuid) {
      const response = await benefitScreeningAPI.getBenefitRecord(
        benefitRecordId
      );
      const benefitRecord = response.data;
      if (
        benefitRecord.facilityId === emptyGuid ||
        benefitRecord.organizationId === emptyGuid ||
        benefitRecord.benefitRecordId === emptyGuid
      ) {
        console.log("BenefitRecord not found");
      } else {
        setBenefitRecord(benefitRecord);
      }
    } else {
      console.log("No BenefitRecordId in local storage...");
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error);
    }
  }
};

const saveBenefitRecord = async () => {
  savingBenefitRecord.value = true;
  try {
    const payload = state;

    const response = await benefitScreeningAPI.postBenefitRecord(payload);
    const benefitRecord = response.data;
    benefitRecord.forms = benefitRecord.forms.filter((f) => f.selected == true);
    setBenefitRecord(benefitRecord);
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error);
    }
  }
  savingBenefitRecord.value = false;
};

const determineEligibility = async () => {
  try {
    const payload = state;
    const response = await benefitScreeningAPI.getEligibility(payload);
    const benefitRecord = response.data;
    setBenefitRecord(benefitRecord);
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error);
    }
  }
};

const setBenefitRecord = (benefitRecord) => {
  Object.assign(state, benefitRecord);
};

const setBenefitRecordId = (benefitRecordId) => {
  localStorage.setItem("benefitRecordId", benefitRecordId);
  state.benefitRecordId = benefitRecordId;
};

const setLanguageCode = (languageCode) => {
  state.languageCode = languageCode;
  setLanguage(languageCode);
};

const setFirstName = (firstName) => {
  state.firstName = firstName;
};

const setLastName = (lastName) => {
  state.lastName = lastName;
};

const setDOB = (dob) => {
  state.primaryResidence.residents[0].dob = dob;
};

const setEmail = (email) => {
  state.email = email;
};

const setSSN = (ssn) => {
  state.ssn = ssn;
};

const setPhone = (phone) => {
  state.phone = phone;
};

const addNote = (message, advocate = null) => {
  const currentAccount = state.visits.filter(
    (account) => account.accountNumber === state.accountNumber
  );
  if (!currentAccount.length) return;
  const currentTime = new Date();

  const newNote = {
    body: message,
    accountId: currentAccount.visitId,
    accountNumber: currentAccount.accountNumber,
    addedBy: advocate ? advocate : "API",
    addedAt: currentTime.toISOString(),
  };

  state.notes.push(newNote);
};

const setStatus = (status) => {
  state.status = status;
};

const setChannel = (channel) => {
  state.channel = channel;
};

const setAddress1 = (address1) => {
  state.primaryResidence.address.address1 = address1;
};

const setAddress2 = (address2) => {
  state.primaryResidence.address.address2 = address2;
};

const setCity = (city) => {
  state.primaryResidence.address.city = city;
};

const setResidencyState = (residencyState) => {
  state.primaryResidence.address.state = residencyState;
};

const setZipCode = (zipCode) => {
  state.primaryResidence.address.zipCode = zipCode;
};

const addResident = (resident) => {
  state.residentsCount++;
  state.primaryResidence.residents.push(resident);
};

const editResident = (updatedResident) => {
  const resident = state.primaryResidence.residents.find(
    (resident) => resident.residentId === updatedResident.residentId
  );
  if (resident) {
    // check if we need to update the sex question answer as well (I hate this)
    if (updatedResident.sex !== resident.sex) {
      const sexQuestion = findQuestionRecursively(
        sexQuestionGuid,
        resident.questionsAnswers
      );
      if (sexQuestion) sexQuestion.answer = updatedResident.sex;
    }
    // update the resident
    Object.assign(resident, updatedResident);
  }
  // check if this is the primary resident
  if (resident.residentId === primaryResident.value.residentId) {
    // set all the matching benefitRecord values to the primary resident's value (yea, this sucks too)
    syncBenefitRecordToPrimaryResident(resident);
  }
};

const removeResident = async (residentId) => {
  state.residentsCount--;
  state.primaryResidence.residents = state.primaryResidence.residents.filter(
    (resident) => resident.residentId !== residentId
  );
  await saveBenefitRecord();
};

const syncBenefitRecordToPrimaryResident = (resident) => {
  if (state.firstName !== resident.firstName)
    state.firstName = resident.firstName;
  if (state.lastName !== resident.lastName) state.lastName = resident.lastName;
  if (state.sex !== resident.sex) state.sex = resident.sex;
  if (state.dob !== resident.dob) state.dob = resident.dob;
  if (state.ssn !== resident.dob) state.ssn = resident.ssn;
};

const addAsset = (asset) => {
  const resident = residents.value.find(
    (resident) => resident.residentId === asset.residentId
  );

  if (resident) {
    switch (asset.category) {
      case assetCategories.general:
        resident.assets.generalAssets.push({
          id: newGuid(),
          generalAssetType: asset.type,
          value: asset.value,
          label: asset.description,
        });
        break;
      case assetCategories.financial:
        resident.assets.financialAssets.push({
          id: newGuid(),
          financialAssetType: asset.type,
          value: asset.value,
          label: asset.description,
        });
        break;
      case assetCategories.realEstate:
        resident.assets.realEstateAssets.push({
          id: newGuid(),
          realEstateAssetType: asset.type,
          value: asset.value,
          label: asset.description,
        });
        break;
      case assetCategories.vehicle:
        resident.assets.vehicleAssets.push({
          id: newGuid(),
          vehicleAssetType: asset.type,
          value: asset.value,
          label: asset.description,
        });
        break;
      case assetCategories.insurance:
        resident.assets.insuranceAssets.push({
          id: newGuid(),
          insuranceAssetType: asset.type,
          value: asset.value,
          label: asset.description,
        });
        break;
    }
  }
};

const editAsset = (asset) => {
  const originalAsset = assets.value.find(
    (originalAsset) => originalAsset.id === asset.id
  );

  if (originalAsset) {
    const resident = residents.value.find(
      (resident) => resident.residentId === originalAsset.residentId
    );

    if (resident) {
      // remove the old asset (incase we changed it's category)
      switch (originalAsset.category) {
        case assetCategories.general:
          resident.assets.generalAssets = resident.assets.generalAssets.filter(
            (generalAsset) => generalAsset.id !== originalAsset.id
          );
          break;
        case assetCategories.financial:
          resident.assets.financialAssets = resident.assets.financialAssets.filter(
            (financialAsset) => financialAsset.id !== originalAsset.id
          );
          break;
        case assetCategories.realEstate:
          resident.assets.realEstateAssets = resident.assets.realEstateAssets.filter(
            (realEstateAsset) => realEstateAsset.id !== originalAsset.id
          );
          break;
        case assetCategories.vehicle:
          resident.assets.vehicleAssets = resident.assets.vehicleAssets.filter(
            (vehicleAsset) => vehicleAsset.id !== originalAsset.id
          );
          break;
        case assetCategories.insurance:
          resident.assets.insuranceAssets = resident.assets.insuranceAssets.filter(
            (insuranceAsset) => insuranceAsset.id !== originalAsset.id
          );
          break;
      }

      // add the updated (new) asset with a new id
      addAsset(asset);
    }
  }
};
//#endregion ACTIONS

export default () => {
  return {
    savingBenefitRecord,
    benefitRecord: state,
    ...toRefs(state),
    selectedForms,
    formattedDOB,
    address1,
    address2,
    city,
    residencyState,
    zipCode,
    residents,
    assets,
    expenses,
    residentOptions,
    primaryResident,
    loginAttempts,
    loadBenefitRecordId,
    loadBenefitRecord,
    saveBenefitRecord,
    determineEligibility,
    setBenefitRecord,
    setBenefitRecordId,
    setLanguageCode,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    addNote,
    setStatus,
    setDOB,
    setChannel,
    setSSN,
    setAddress1,
    setAddress2,
    setCity,
    setResidencyState,
    setZipCode,
    addResident,
    editResident,
    removeResident,
    addAsset,
    editAsset,
  };
};
