<template>
  <div class="w-screen min-h-screen flex flex-col justify-start items-center">
    <Header />
    <div class="w-full max-w-7xl flex flex-col items-center">
      <div class="w-full flex flex-col justify-center max-w-2xl">
        <Content />
        <div class="w-full p-2" v-if="$route.name === 'screen'">
          <div
            class="w-full flex justify-center items-center text-sm my-2"
            v-if="loading"
          >
            <i class="fas fa-circle-notch fa-spin mr-2"></i> Loading member...
          </div>
          <!-- User is logged in -->
          <div v-if="!loading && isAuthenticated">
            <div v-if="benefitRecords.length">
              <h2 class="text-xl sm:text-2xl font-bold my-2">Confirm Member</h2>
              <button
                v-for="benefitRecord in benefitRecords"
                :key="benefitRecord.benefitRecordId"
                class="w-full p-4 shadow rounded border flex justify-between items-center my-2"
                @click="setQuickscreen(benefitRecord.benefitRecordId)"
              >
                <div class="text-left">
                  <h6 class="font-semibold flex items-center">
                    {{ benefitRecord.firstName }} {{ benefitRecord.lastName }}
                    <span class="font-light italic text-sm"
                      >({{ benefitRecord.medicalRecordId }})</span
                    >
                  </h6>
                  <p class="text-sm">{{ formatDOB(benefitRecord.dob) }}</p>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="ai ai-ChevronRight"
                  >
                    <path d="M8 4l8 8-8 8" />
                  </svg>
                </div>
              </button>
            </div>
            <div
              v-else-if="!benefitRecords.length"
              class="flex justify-center items-center my-2 p-4 border-red-500 border rounded bg-red-50"
            >
              <p class="text-red-500">
                No records found
                {{ existingRecordId.length ? `for ${existingRecordId}` : "" }}.
                Please try again.
              </p>
            </div>
          </div>
          <!-- User is not logged in -->
          <div v-else-if="!loading && !isAuthenticated">
            <h2 class="text-xl sm:text-2xl font-bold my-2">Confirm Birthday</h2>
            <TextField
              label=""
              placeholder="MM/DD/YYYY"
              :modelValue="dob"
              @textChange="setDOB"
              :mask="'##/##/####'"
              :pattern="'[0-9]*'"
              labelClasses="font-medium text-xs mb-2"
              inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
              focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
            />
            <button
              class="w-full items-center py-3 rounded-md bg-primary shadow text-white z-10 relative next-btn mt-2"
              @click="loadUnauthorizedExistingQuickscreen"
              :class="loading || dobLimitReached ? 'cursor-not-allowed' : ''"
              :disabled="loading || dobLimitReached"
            >
              <span v-if="loading">
                <i class="fas fa-lg fa-circle-notch fa-spin"></i>
              </span>
              <span v-else>Confirm</span>
            </button>
            <div style="margin-top: 20px" v-if="showDobError">
              <Alert v-bind:errorMessage="dobErrorMessage" />
            </div>
          </div>
        </div>
        <div class="w-full flex">
          <router-view />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGtag } from "vue-gtag-next";
import { formatDOB } from "../../utils/functions";
import benefitAPI from "../../services/benefitAPI";
import useQuickscreen from "../../composables/useQuickscreen";
import useBenefitRecord from "../../composables/useBenefitRecord";
import Header from "../../components/screen/layout/Header.vue";
import Footer from "../../components/screen/layout/Footer.vue";
import Content from "../../components/screen/layout/Content.vue";
import TextField from "../../components/common/ui/TextField.vue";
import Alert from "../../components/common/ui/Alert.vue";
import useAuth from "../../composables/useAuth";
import benefitScreeningAPI from "../../services/benefitScreeningAPI";
export default {
  components: {
    Header,
    Footer,
    Content,
    TextField,
    Alert,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      channel,
      setChannel,
      setLanguageCode,
      setBenefitRecord,
      loginAttempts,
    } = useBenefitRecord();
    const {
      authorize,
      currentStepIndex,
      steps,
      loadFacility,
      loadQuickscreen,
      saveQuickscreen,
      transition,
      setInitialQuestion,
      facilityId,
    } = useQuickscreen();
    const { accessToken, isAuthenticated, loadAuth } = useAuth();
    const { query } = useGtag();

    const loading = ref(true);

    const benefitRecords = ref([]);

    const existingRecordKeys = ["member"];

    const existingRecordId = ref("");

    const dob = ref(null);

    const showDobError = ref(false);
    const dobLimitReached = ref(false);
    const dobErrorMessage = ref("");

    const setDOB = (newDOB) => {
      dob.value = newDOB;
    };

    const loadAuthorizedExistingQuickscreen = async () => {
      loading.value = true;
      try {
        existingRecordId.value = route.query.member;
        const response = await benefitAPI.getBenefitRecordByMRN(
          accessToken.value,
          route.query.member
        );
        benefitRecords.value = response.data.data;
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
    };

    const loadUnauthorizedExistingQuickscreen = async () => {
      loading.value = true;
      showDobError.value = false;
      try {
        existingRecordId.value = route.query.member;

        const payload = {
          mrn: existingRecordId.value,
          dob: dob.value,
          facilityId: facilityId.value,
        };

        const response = await benefitScreeningAPI
          .verifyDOB(payload)
          .catch((error) => {
            if (error.response.status == "400") {
              throw new Error("Invalid DoB");
            } else if (error.response.status == "403") {
              throw new Error("DOB LIMIT");
            } else {
              throw error;
            }
          });

        const benefitRecord = response.data;

        setBenefitRecord(benefitRecord);
        await saveQuickscreen();
        setInitialQuestion();
        router.push(steps.value[currentStepIndex.value].route);
      } catch (error) {
        if (error.message == "Invalid DoB") {
          showDobError.value = true;
          dobErrorMessage.value = "Incorrect birthday. Please try again.";
        } else if (error.message.toUpperCase() == "DOB LIMIT") {
          dobLimitReached.value = true;
          dobErrorMessage.value = "Maximum attempts reached.";
          showDobError.value = true;
        } else console.log(error.message);
      }
      loading.value = false;
    };

    const setQuickscreen = async (benefitRecordId) => {
      const benefitRecord = benefitRecords.value.find(
        (benefitRecord) => benefitRecord.benefitRecordId === benefitRecordId
      );
      setBenefitRecord(benefitRecord);
      await saveQuickscreen();
      setInitialQuestion();
      router.push(steps.value[currentStepIndex.value].route);
    };

    const loadNewQuickscreen = async () => {
      await loadQuickscreen();
      const newChannel = route.query["utm_medium"];
      if (newChannel) {
        setChannel(newChannel);
      }

      const languageCode = route.query["lang"];
      if (languageCode) {
        setLanguageCode(languageCode);
        await saveQuickscreen();
      }
      router.push(steps.value[currentStepIndex.value].route);
    };

    onMounted(async () => {
      query("event", "Page View", {
        event_category: channel,
      });

      loadFacility();
      await loadAuth();
      if (authorize.value && !isAuthenticated.value) {
        router.push({ name: "login" });
        return;
      }

      if (
        existingRecordKeys.some((key) => Object.keys(route.query).includes(key))
      ) {
        if (authorize.value) {
          await loadAuthorizedExistingQuickscreen();
        } else {
          loading.value = false;
        }
      } else await loadNewQuickscreen();
    });

    return {
      loading,
      transition,
      benefitRecords,
      formatDOB,
      setQuickscreen,
      existingRecordId,
      isAuthenticated,
      dob,
      setDOB,
      loadUnauthorizedExistingQuickscreen,
      showDobError,
      dobLimitReached,
      dobErrorMessage,
      loginAttempts,
    };
  },
};
</script>

<style scoped></style>
