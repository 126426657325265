import { reactive, toRefs } from "@vue/reactivity";
import jwt_decode from "jwt-decode";
import benefitAPI from "../services/benefitAPI";
import router from "../router";

const initialState = {
  isAuthenticated: false,
  roles: [],
  userId: null,
  email: null,
  accessToken: null,
  refreshToken: null,
};
const state = reactive({});
Object.assign(state, initialState);

const login = async (payload) => {
  try {
    const response = await benefitAPI.login(payload);
    const { accessToken, refreshToken } = response.data.data;

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    return response.data;
  } catch (error) {
    if (error.response) {
      console.log(error.response);
      return error.response.data;
    } else {
      return {
        success: false,
        message: "Unable to login user.",
        data: ["An unknown error has occured. Please try again."],
      };
    }
  }
};

const loadAuth = async () => {
  const localRefreshToken = localStorage.getItem("refreshToken");
  if (localRefreshToken) {
    state.refreshToken = localRefreshToken;
    await refreshAccessToken();
  }
};

const refreshAccessToken = async () => {
  try {
    const payload = { token: state.refreshToken };
    const response = await benefitAPI.refreshToken(payload);
    const { accessToken, refreshToken } = response.data.data;

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error);
    }
    router.push({ name: "login" });
  }
};

const logout = async () => {
  try {
    const payload = state.accessToken;
    await benefitAPI.logout(payload);
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error);
    }
  }
  localStorage.removeItem("refreshToken");
  Object.assign(state, initialState);
};

const setAccessToken = (accessToken) => {
  const { nameid, email, role, exp } = jwt_decode(accessToken);

  const msToExpiration = Math.abs(new Date() - new Date(exp * 1000));
  setSilentRefesh(msToExpiration);

  state.userId = nameid;
  state.email = email;
  state.roles = typeof role === "string" ? [role] : role;
  console.log("hit set access token" + " " + state.isAuthenticated);
  state.isAuthenticated = true;
  state.accessToken = accessToken;
};

const setRefreshToken = (refreshToken) => {
  state.refreshToken = refreshToken;
  localStorage.setItem("refreshToken", refreshToken);
};

const setSilentRefesh = (msToExpiration) => {
  // silently refresh the token 10 seconds before it is supposed to expire
  setTimeout(refreshAccessToken, msToExpiration - 10000);
};

export default () => {
  return {
    ...toRefs(state),
    login,
    loadAuth,
    logout,
  };
};
