<template>
  <header class="w-full h-14 bg-white shadow flex justify-center items-center">
    <div class="w-full max-w-7xl flex justify-between items-center px-1">
      <div class="flex justify-center items-center">
        <img :src="theme.logo" alt="logo" :class="theme.logoSize" />
      </div>

      <LanguageSelect />
    </div>
  </header>
</template>

<script>
import useQuickscreen from "../../../composables/useQuickscreen";
import LanguageSelect from "../../common/ui/LanguageSelect.vue";
export default {
  components: {
    LanguageSelect,
  },
  setup() {
    const { theme } = useQuickscreen();

    return {
      theme,
    };
  },
};
</script>

<style></style>
