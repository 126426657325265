import { reactive, toRefs } from "vue";
import { defaultFacility, facilities } from "../common/facilities";
import benefitScreeningAPI from "../services/benefitScreeningAPI";

const state = reactive({
  facility: {},
  organization: {},
});

const loadFacility = async () => {
  let facilityId = defaultFacility.facilityId;
  const hostname = window.location.hostname;
  if (hostname in facilities) facilityId = facilities[hostname].facilityId;
  try {
    const response = await benefitScreeningAPI.getFacility(facilityId);
    const { organization, facility } = response.data;
    setOrganization(organization);
    setFacility(facility);
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error);
    }
  }
};

const setOrganization = (organization) => {
  state.organization = organization;
};

const setFacility = (facility) => {
  state.facility = facility;
};

export default () => {
  return {
    ...toRefs(state),
    loadFacility,
  };
};
