import axios from "axios";

const apiClient = axios.create({
  //baseURL: "https://localhost:44358",
  baseURL: "https://api.benefitscreening.com",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  login(payload) {
    return apiClient.post("/auth/login", payload);
  },
  refreshToken(payload) {
    return apiClient.post("/auth/refresh-token", payload);
  },
  logout(accessToken) {
    return apiClient.delete("/auth/logout", {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  getScreenings(accessToken) {
    return apiClient.get("/user/screenings", {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  getBenefitRecordByMRN(accessToken, mrn) {
    return apiClient.get(`/benefit-record?mrn=${mrn}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
};
