<template>
  <!--<div class="w-full text-center">-->

  <div class="grid-rows-2 gap-x-4 gap-y-6">
    <!--
    <div style="margin-top: 10px">
          <button
      class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded"
      :disabled="!termsChecked"
      style="margin-top: 15px"
      id="screeningProceedButton"
      @click="nextStep"
    >
      Proceed to screening
    </button>
    </div>
    -->
    <div class="form-check" style="margin-top: 15px">
      <input
        class="terms-input h-4 w-4 border border-gray-300 rounded-sm bg-white focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        :checked="termsAndConditionsAcknowledged"
        @click="swapAcknowledgement"
      />
      <label
        class="form-check-label inline-block text-gray-800"
        for="flexCheckDefault"
      >
        {{ language.acknowledgeTerms }}
        <button
          style="display: inline-block"
          class="mb-4 px-1 text-blue-500 font-medium text-sm flex justify-center items-center"
          @click="displayShowTermsModal"
        >
          {{ language.termsConditions }}
        </button>
      </label>
    </div>

    <TermsModal
      :showModal="showTermsModal"
      @hideModal="hideShowTermsModel"
      @addAcknowledgement="acknowledgeTerms"
    />
  </div>
</template>

<script>
import TermsModal from "../../components/common/ui/TermsModal.vue";
import { reactive, toRefs } from "@vue/reactivity";
import useQuickscreen from "../../composables/useQuickscreen";
import { useContext } from "@vue/runtime-core";
import useTerms from "../../composables/useTerms";
import useLanguage from "../../composables/useLanguage";

export default {
  components: {
    TermsModal,
  },

  setup() {
    const { language } = useLanguage();

    const { emit } = useContext();
    const state = reactive({
      showTermsModal: false,
      termsChecked: false,
    });

    const nextStep = useQuickscreen().nextStep;
    const {
      termsAndConditionsAcknowledged,
      swapAcknowledgement,
      acknowledgeTerms,
    } = useTerms();
    const displayShowTermsModal = () => {
      state.showTermsModal = true;
      console.log("show terms state: " + state.showTermsModal);
    };
    const hideShowTermsModel = () => {
      state.showTermsModal = false;
    };

    const termsCheckButtonClicked = () => {
      state.termsChecked = !state.termsChecked;
      console.log(state.termsChecked);
      emit("termsChecked", state.termsChecked);
    };
    const termsAcknowledged = () => {
      console.log("terms acknowledged: " + state.termsChecked);
      return state.termsChecked;
    };
    const checkTerms = () => {
      console.log("checking terms: " + state.termsChecked);
      state.termsChecked = true;
      emit("termsChecked", true);
    };

    return {
      //toggleModal,
      ...toRefs(state),
      displayShowTermsModal,
      hideShowTermsModel,
      termsAcknowledged,
      checkTerms,
      termsCheckButtonClicked,
      nextStep,
      termsAndConditionsAcknowledged,
      swapAcknowledgement,
      acknowledgeTerms,
      language,
    };
  },
};
</script>

<style>
#testCheckBox[type="checkbox"]:checked {
  text-align: center;
  line-height: 15px;
  background: transparent url(/img/checkbox-drawn.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: mistyrose;
}

#screeningProceedButton:disabled {
  background-color: #ffe6cf;
}
</style>
