<template>
  <Modal :showModal="showModal">
    <div
      class="w-full max-w-md rounded relative bg-white shadow"
      v-click-away="hideModal"
    >
      <div
        class="w-full border-b bg-gray-50 flex justify-between items-center p-3 rounded-t"
      >
        <div>
          <h1 class="text-sm uppercase text-gray-500 font-semibold">
            Add Expense
          </h1>
        </div>
        <button
          @click="hideModal"
          class="hover:bg-gray-200 w-6 h-6 rounded flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ai ai-Cross"
          >
            <path d="M20 20L4 4m16 0L4 20" />
          </svg>
        </button>
      </div>
      <div class="p-4">
        <div class="grid grid-cols-1 gap-y-4">
          <Select
            :label="'Owner'"
            :placeholder="'Select owner'"
            :options="residentOptions"
            :modelValue="residentId"
            @select="setResidentId"
            labelClasses="font-medium text-xs mb-2"
            selectClasses="bg-gray-50 py-2 pl-4 pr-2 border border-gray-300 rounded flex justify-between items-center text-sm"
            focusClasses="bg-gray-50 border border-primary rounded"
          />
          <Select
            :label="'Category'"
            :placeholder="'Select category'"
            :modelValue="category"
            :options="expenseCategoryOptions"
            @select="setCategory"
            labelClasses="font-medium text-xs mb-2"
            selectClasses="bg-gray-50 py-2 pl-4 pr-2 border border-gray-300 rounded flex justify-between items-center text-sm"
            focusClasses="bg-gray-50 border border-primary rounded"
          />
          <Select
            :label="'Type'"
            :placeholder="'Select type'"
            :options="expenseTypes[category]"
            :modelValue="type"
            @select="setType"
            labelClasses="font-medium text-xs mb-2"
            selectClasses="bg-gray-50 py-2 pl-4 pr-2 border border-gray-300 rounded flex justify-between items-center text-sm"
            focusClasses="bg-gray-50 border border-primary rounded"
          />
          <TextField
            label="Description"
            placeholder="Enter description"
            :modelValue="description"
            @textChange="setDescription"
            labelClasses="font-medium text-xs mb-2"
            inputClasses="bg-gray-50 py-2 px-4 border border-gray-300 rounded text-sm"
            focusClasses="bg-gray-50 py-2 px-4 border border-primary rounded text-sm"
          />
        </div>
      </div>
      <div
        class="border-t p-3 flex justify-between items-center bg-gray-50 rounded-b"
      >
        <button
          @click="hideModal"
          class="py-3 font-medium text-xs w-1/2 text-blue-500"
        >
          Cancel
        </button>
        <button
          @click="addNewExpense"
          class="py-3 w-1/2 text-xs btn-primary rounded border font-medium"
        >
          Add Expense
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import {
  expenseCategories,
  expenseCategoryOptions,
  expenseTypes,
} from "../../../common/constants";
import { reactive, toRefs } from "@vue/reactivity";
import useBenefitRecord from "../../../composables/useBenefitRecord";
import Modal from "../../common/ui/Modal.vue";
import Select from "../../common/ui/Select.vue";
import TextField from "../../common/ui/TextField.vue";
export default {
  components: {
    Modal,
    Select,
    TextField,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(_, { emit }) {
    const hideModal = () => {
      emit("hideModal");
    };
    const { residentOptions } = useBenefitRecord();
    const state = reactive({
      category: expenseCategories.general,
      type: expenseTypes[expenseCategories.general][0].value,
      residentId: null,
      description: "",
    });

    const setCategory = (category) => {
      state.category = category;
      state.type = expenseTypes[category][0].value;
    };

    const setType = (type) => {
      state.type = type;
    };

    const setResidentId = (residentId) => {
      state.residentId = residentId;
    };

    const setDescription = (description) => {
      state.description = description;
    };

    const addNewExpense = async () => {
      emit("hideModal");
    };

    return {
      residentOptions,
      expenseCategories,
      expenseCategoryOptions,
      expenseTypes,
      ...toRefs(state),
      hideModal,
      setCategory,
      setType,
      setResidentId,
      setDescription,
      addNewExpense,
    };
  },
};
</script>

<style></style>
