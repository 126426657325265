<template>
  <div
    class="w-full h-full relative pt-14 overflow-hidden"
    :style="{ maxHeight: `${height}px` }"
  >
    <Header />
    Search results
  </div>
</template>

<script>
import useFacility from "../../composables/useFacility";
import useWindowSize from "../../composables/useWindowSize";
import Header from "../../components/common/layout/Header.vue";
import { onMounted } from "@vue/runtime-core";
export default {
  components: {
    Header,
  },
  setup() {
    const { loadFacility } = useFacility();
    const { width, height } = useWindowSize();

    onMounted(async () => {
      await loadFacility();
    });

    return {
      width,
      height,
    };
  },
};
</script>
