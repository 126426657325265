<template>
  <div class="w-screen h-screen flex flex-col justify-center items-center">
    <Header />
    <h1 class="text-2xl font-semibold mt-4">Page Not Found.</h1>
  </div>
</template>

<script>
import Header from "../components/common/layout/Header.vue";
export default {
  components: {
    Header,
  },
};
</script>

<style></style>
