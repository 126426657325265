<template>
  <div class="relative ml-1" v-if="email">
    <button
      class="flex justify-center items-center rounded hover:bg-gray-100 w-8 h-8"
      @click="displayUserModal"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="ai ai-Person"
      >
        <circle cx="12" cy="7" r="5" />
        <path
          d="M17 14h.352a3 3 0 0 1 2.976 2.628l.391 3.124A2 2 0 0 1 18.734 22H5.266a2 2 0 0 1-1.985-2.248l.39-3.124A3 3 0 0 1 6.649 14H7"
        />
      </svg>
    </button>
    <div
      v-if="showUserModal"
      v-click-away="hideUserModal"
      class="w-64 right-0 top-100 bg-white rounded border shadow-lg absolute mt-2 py-2"
    >
      <div class="w-full flex flex-col justify-center items-center p-2">
        <div
          class="w-10 h-10 rounded-full flex justify-center items-center bg-primary-light text-primary text-2xl uppercase"
        >
          {{ email[0] }}
        </div>
        <p class="text-xs mt-2">{{ email }}</p>
      </div>

      <hr />
      <button
        class="hover:bg-gray-200 rounded w-full text-sm py-2 px-6 mt-2 text-left"
        @click="logoutUser"
      >
        Logout
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import useAuth from "../../../composables/useAuth";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const { email, logout } = useAuth();

    const state = reactive({
      showUserModal: false,
    });

    const displayUserModal = () => {
      state.showUserModal = true;
    };

    const hideUserModal = () => {
      state.showUserModal = false;
    };

    const logoutUser = async () => {
      await logout();
      router.push({ name: "login" });
    };

    return {
      ...toRefs(state),
      displayUserModal,
      hideUserModal,
      email,
      logoutUser,
    };
  },
};
</script>

<style></style>
