<template>
  <div class="flex flex-col relative cursor-pointer" :class="wrapperClasses">
    <label for="" :class="labelClasses">{{ label }} </label>
    <div
      :class="`${state.getSelectClass()} ${
        disabled ? 'cursor-default text-gray-500' : ''
      }`"
      v-click-away="state.hideOptions"
      @click="state.showOptions = disabled ? false : !state.showOptions"
    >
      <p :class="state.selected === '' ? 'text-gray-500 font-light' : ''">
        {{ state.selected === "" ? placeholder : state.selected.text }}
      </p>
      <svg
        v-if="!state.showOptions"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="ai ai-ChevronDown"
      >
        <path d="M4 9l8 8 8-8" />
      </svg>
      <svg
        v-if="state.showOptions"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="ai ai-ChevronUp"
      >
        <path d="M4 15l8-8 8 8" />
      </svg>
    </div>

    <transition name="slide-down">
      <div
        class="left-0 right-0 absolute h-auto overflow-y-auto max-h-56 top-full z-20 border-1 rounded mt-1 py-2 options shadow-md"
        v-show="state.showOptions"
      >
        <button
          class="w-full px-4 flex justify-start items-center h-8 option text-gray-600 text-sm"
          v-for="option in state.filteredOptions()"
          :key="option.value"
          @click="state.selectOption(option)"
        >
          {{ option.text }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from "vue";
export default {
  props: {
    wrapperClasses: {
      type: String,
      required: false,
      default: "",
    },
    labelClasses: {
      type: String,
      required: false,
      default: "text-gray-700 text-sm pb-1",
    },
    selectClasses: {
      type: String,
      required: false,
      default:
        "bg-gray-50 rounded pl-4 pr-2 py-2 border-1 flex justify-between items-center",
    },
    focusClasses: {
      type: String,
      required: false,
      default: "border-secondary bg-secondary-light",
    },
    label: {
      type: String,
      required: true,
      default: "Select",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: true,
      default: "Select an option",
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    excludeOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["select"],
  setup(props, { emit }) {
    const state = reactive({
      selected: "",
      showOptions: false,
      hideOptions: () => (state.showOptions = false),
      selectOption: (option) => {
        emit("select", option.value);
        state.selected = option;
        state.hideOptions();
      },
      getSelectClass: () => {
        const anySelected = state.selected === "" ? "" : "bg-blue-50";
        return state.showOptions
          ? `${props.selectClasses} ${props.focusClasses} ${anySelected}`
          : `${props.selectClasses} ${anySelected}`;
      },
      setInitialValue: () => {
        if (props.modelValue) {
          const option = props.options.find((option) => {
            return option.value === props.modelValue;
          });
          if (option) {
            state.selected = option;
          }
        } else {
          state.selected = "";
        }
      },
      filteredOptions: () => {
        return props.options.filter(
          (option) => !props.excludeOptions.includes(option.value)
        );
      },
    });

    watch(
      () => props.modelValue,
      () => {
        state.setInitialValue();
      }
    );

    onMounted(() => {
      state.setInitialValue();
    });

    return {
      state,
    };
  },
};
</script>

<style scoped>
.option:hover {
  background-color: theme("colors.gray.100");
  color: theme("colors.gray.900");
}

.options {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  background-color: white;
}
.options::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
