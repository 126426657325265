<template>
  <div class="w-full text-center">
    <img
      src="https://quickscreen-assets.s3.us-east-2.amazonaws.com/img/hand-reaching.jpg"
      alt=""
    />
    <div class="px-2">
      <p class="text-lg my-4">
        <span v-if="firstName !== 'New'">{{ firstName }}, thank</span>
        <span v-else>Thank</span> you for starting the enrollment process. An
        Advocatia representative will reach out to you.
      </p>
      <p class="text-lg mb-4" v-if="!disableTriage">
        {{ language.wantToStart }}
      </p>
      <p
        class="text-red-500 text-lg w-full text-left"
        v-if="errorMessage.length"
      >
        {{ errorMessage }}
      </p>
      <TextField
        v-if="!disableTriage"
        label=""
        placeholder="MM/DD/YYYY"
        :modelValue="dob"
        @textChange="setNewDOB"
        :wrapperClasses="'mb-4'"
        :inputClasses="
          !errorMessage.length
            ? 'bg-gray-50 rounded px-4 py-2 border-1 text-lg'
            : 'bg-gray-50 rounded px-4 py-2 border-1 text-lg border-red-500'
        "
        :focusClasses="'rounded px-4 py-2 border-1 border-secondary bg-secondary-light text-lg'"
        :mask="'##/##/####'"
        :pattern="'[0-9]*'"
      />
      <button
        @click="startProcess"
        v-if="
          triageSubdomain.length && benefitRecordId.length && !disableTriage
        "
        :disabled="loading"
        class="w-full items-center py-3 rounded-md btn-primary shadow text-white mt-4"
      >
        <span v-if="!loading">{{ language.startProcess }}</span>
        <span v-else> <i class="fas fa-lg fa-circle-notch fa-spin"></i> </span>
      </button>
    </div>
  </div>
</template>

<script>
import useBenefitRecord from "../../composables/useBenefitRecord";
import useLanguage from "../../composables/useLanguage";
import useQuickscreen from "../../composables/useQuickscreen";
import TextField from "../../components/common/ui/TextField.vue";
import { ref } from "@vue/reactivity";

export default {
  components: {
    TextField,
  },
  setup() {
    const {
      benefitRecordId,
      firstName,
      setDOB,
      saveBenefitRecord,
    } = useBenefitRecord();
    const { triageSubdomain, disableTriage } = useQuickscreen();
    const { language } = useLanguage();

    const errorMessage = ref("");
    const loading = ref(false);
    const dob = ref("");
    const setNewDOB = (newDOB) => {
      errorMessage.value = "";
      dob.value = newDOB;
    };

    const startProcess = async () => {
      errorMessage.value = "";
      if (dob.value.length === 10) {
        loading.value = true;
        setDOB(dob.value);
        await saveBenefitRecord();
        loading.value = false;
        window.open(
          `https://${
            triageSubdomain.value
          }.triage.benefitscreening.com/welcome/${benefitRecordId.value}/${
            new Date(dob.value).toISOString().split("T")[0]
          }`,
          "_blank"
        );
      } else {
        console.log("invalid");
        errorMessage.value = "Please enter a valid date";
      }
    };

    return {
      firstName,
      benefitRecordId,
      triageSubdomain,
      language,
      dob,
      setNewDOB,
      startProcess,
      loading,
      errorMessage,
      disableTriage,
    };
  },
};
</script>

<style></style>
