<template>
  <div
    class="w-full h-full relative pt-14 overflow-hidden"
    :style="{ maxHeight: `${height}px` }"
  >
    <Header />
    <BenefitRecordModal
      :showModal="showBenefitRecordModal"
      @hideModal="hideBenefitRecordModal"
    />
    <transition name="slide-left">
      <Sidebar
        v-show="showSidebar || width > 1023"
        @displayBenefitRecordModal="displayBenefitRecordModal"
      />
    </transition>
    <div
      class="w-full h-full fixed top-0 left-0 z-20 block modal lg:hidden"
      v-if="showSidebar"
      @click="hideSidebar"
    ></div>
    <div
      :class="wrapperClass"
      class="w-full overflow-y-auto flex justify-start"
      id="page-wrapper"
      :style="{ height: `calc(${height}px - 3.5rem)` }"
    >
      <div class="p-2 sm:p-4 w-full flex justify-center items-start">
        <div
          :class="hasMaxWidth ? 'max-w-6xl' : ''"
          class="w-full flex justify-center items-start pb-4"
        >
          <slot></slot>
        </div>
      </div>
      <div
        class="absolute bottom-0 right-0 text-xs m-4 p-1 bg-yellow-100 rounded"
        v-show="savingBenefitRecord"
      >
        Saving...
      </div>
      <div class="absolute bottom-0 lg:left-72 text-xs m-4 bg-white">
        Support Code: {{ benefitRecordId }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from "@vue/runtime-core";
import useSidebar from "../../../composables/useSidebar";
import useFacility from "../../../composables/useFacility";
import useWindowSize from "../../../composables/useWindowSize";
import useBenefitRecord from "../../../composables/useBenefitRecord";
import Header from "../../common/layout/Header.vue";
import Sidebar from "../../common/layout/Sidebar.vue";
import BenefitRecordModal from "../ui/BenefitRecordModal.vue";
export default {
  props: {
    hasMaxWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    Header,
    Sidebar,
    BenefitRecordModal,
  },
  setup(props) {
    const { loadFacility } = useFacility();
    const { showSidebar, hideSidebar } = useSidebar();
    const { width, height } = useWindowSize();
    const {
      savingBenefitRecord,
      benefitRecordId,
      loadBenefitRecordId,
      loadBenefitRecord,
    } = useBenefitRecord();

    const state = reactive({
      showBenefitRecordModal: false,
    });

    const displayBenefitRecordModal = () => {
      state.showBenefitRecordModal = true;
    };

    const hideBenefitRecordModal = () => {
      state.showBenefitRecordModal = false;
    };

    onMounted(async () => {
      await loadFacility();
      loadBenefitRecordId();
      await loadBenefitRecord();
    });

    const wrapperClass = computed(() => {
      const classes = [];
      if (showSidebar.value || width.value > 1024) {
        classes.push("lg:pl-72");
        if (props.hasMaxWidth) classes.push("2xl:pr-72");
      } else classes.push("pl-0");

      return classes;
    });

    return {
      savingBenefitRecord,
      benefitRecordId,
      showSidebar,
      hideSidebar,
      width,
      height,
      wrapperClass,
      ...toRefs(state),
      displayBenefitRecordModal,
      hideBenefitRecordModal,
    };
  },
};
</script>
