const apiURLs = {
  local: "https://localhost:44344",
  test: "https://staging.benefitscreening.com",
  production: "https://www.benefitscreening.com",
  dev: "https://dev.benefitscreening.com",
};
const contentSelectionTypes = {
  default: 0,
  firstPageOnly: 1,
  noContent: 2,
};
const questionHeaderTypes = {
  question: 0,
  progress: 1,
};
const defaultFacility = {
  apiURL: apiURLs.test,
  organizationId: "DBAA23F3-6367-44E8-83CD-F10755B2B40A", // Advocatia
  facilityId: "F939BC82-E7D6-4C0E-9402-AA8FDABBCAD1", // Demo Hostpital Digitial Outreach
  gaCode: "G-7CTV5GDWXD",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/advocatia_logo.png",
    logoSize: "h-8",
    primary: "#ff9331",
    primaryLight: "#ffe6cf",
    primaryDark: "#d97706",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  triageSubdomain: "advocatia",
  authorize: false,
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent2", "DefaultContent3", null],
  contact: {
    call: "(XXX) XXX-XXXX",
    textCode: "XXXX",
    text: "(XXX) XXX-XXXX",
    website: "https://www.advocatia.io",
  },
  contentSelectionType: contentSelectionTypes.default,
  questionHeaderType: questionHeaderTypes.question,
};
const uniteUsLouisiana = {
  apiURL: apiURLs.production,
  gaCode: "G-RBJEF6P27J",
  organizationId: "8976C915-64DC-4FE1-BD97-A7794DCF4AA2", // Unite Us
  facilityId: "F450920C-61B8-4B2C-AE74-ADB303C11366", // Louisiana Unite Us (Internal)
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "UniteUsContact" },
  ],
  content: ["UniteUsContent1"],
  contentSelectionType: contentSelectionTypes.firstPageOnly,
  questionHeaderType: questionHeaderTypes.progress,
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/unite_us_louisiana_logo.png",
    primary: "#2A75B0",
    primaryLight: "#EFF6FF",
    primaryDark: "#2E405A",
  },
};
const amita = {
  apiURL: apiURLs.production,
  gaCode: "G-4BFPRHEBFB",
  organizationId: "80567E9C-CD67-4509-BE93-59551048F565", // AMITA Health
  facilityId: "22663341-61D6-4C3B-A56F-E3BE7FC8C963", // Amita
  contact: {
    call: "(312) 584-1212",
    textCode: "312",
    text: "(312) 586-9711",
  },

  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    {
      route: { name: "screen-additional" },
      //component: "AdditionalQuestionEntry",
    },
    { route: { name: "screen-questions" } },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,

  triageSubdomain: "ascensionillinois",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/ascension_logo.png",
    primary: "#1b4297",
    primaryLight: "#E6F4F1",
    primaryDark: "#0e3078",
  },
};
const amitaw = {
  apiURL: apiURLs.production,
  gaCode: "G-1EQ2SPDCXY",
  organizationId: "80567E9C-CD67-4509-BE93-59551048F565", // AMITA Health
  facilityId: "82D3EB6D-CA89-4FBA-B951-259B8482D6BA", // Amita
  contact: {
    call: "(312) 584-1212",
    textCode: "312",
    text: "(312) 586-9711",
  },

  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    {
      route: { name: "screen-additional" },
      //component: "AdditionalQuestionEntry",
    },
    { route: { name: "screen-questions" } },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,

  triageSubdomain: "ascensionillinoisw",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/ascension_logo.png",
    primary: "#1b4297",
    primaryLight: "#E6F4F1",
    primaryDark: "#0e3078",
  },
};
const ascension = {
  apiURL: apiURLs.production,
  gaCode: "G-KGTK8J4KW5",
  organizationId: "80567E9C-CD67-4509-BE93-59551048F565", // AMITA Health
  facilityId: "22663341-61D6-4C3B-A56F-E3BE7FC8C963", // Amita
  contact: {
    call: "(312) 584-1212",
    textCode: "312",
    text: "(312) 586-9711",
  },

  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    {
      route: { name: "screen-additional" },
    },
    { route: { name: "screen-questions" } },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,

  triageSubdomain: "ascensionillinois",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/ascension_logo.png",
    primary: "#1b4297",
    primaryLight: "#E6F4F1",
    primaryDark: "#0e3078",
  },
};
const ascensionw = {
  apiURL: apiURLs.production,
  gaCode: "G-KLB10K2N8Z",
  organizationId: "80567E9C-CD67-4509-BE93-59551048F565", // AMITA Health
  facilityId: "82D3EB6D-CA89-4FBA-B951-259B8482D6BA",
  contact: {
    call: "(312) 584-1212",
    textCode: "312",
    text: "(312) 586-9711",
  },
  triageSubdomain: "ascensionillinoisw",

  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    {
      route: { name: "screen-additional" },
      //component: "AdditionalQuestionEntry",
    },
    { route: { name: "screen-questions" } },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent1", null],

  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/ascension_logo.png",
    primary: "#1b4297",
    primaryLight: "#E6F4F1",
    primaryDark: "#0e3078",
  },

  questionHeaderType: questionHeaderTypes.progress,
};
const froedtert = {
  apiURL: apiURLs.production,
  gaCode: "G-45S87YKL5G",
  organizationId: "AE1500EC-D0DA-46B5-A354-D80143A1ECD5", // Froedtert
  facilityId: "7B613ADA-0CB3-4FDC-9383-290014F7F89B", // FS Digital Enrollment
  contact: {
    call: "(262) 275-4181",
    textCode: "HEALTH",
    text: "(262) 671-3100",
    website: "https://advocatia.io/froedtert-south-enrollment",
  },
  triageSubdomain: "froedtertsouth",
  theme: {
    logo:
      "https://s3.us-east-2.amazonaws.com/selfservice.benefitscreening.com/logos/froedtertsouth.png",
    primary: "#214598",
    primaryLight: "#C4D1FF",
    primaryDark: "#4E65BD",
  },
  questionHeaderType: questionHeaderTypes.progress
};
const renownRegional = {
  apiURL: apiURLs.test,
  gaCode: "G-QD0ZKX20GX",
  organizationId: "7D647ED2-825D-4012-AD47-CBC88FEE8612", // Renown
  facilityId: "45AFF294-2611-480F-A019-1C16D640F81C", // Renown Regional
  theme: {
    logo:
      "https://s3.us-east-2.amazonaws.com/selfservice.benefitscreening.com/logos/renown-regional.png",
  },
  contact: {
    call: "(XXX) XXX-XXXX",
    textCode: "XXXX",
    text: "(XXX) XXX-XXXX",
    website: "https://www.advocatia.io",
  },
};
const riverside = {
  apiURL: apiURLs.production,
  gaCode: "G-771HMB2H6P",
  organizationId: "78C9A866-0C33-40B5-90A1-D849C6FD6759", // Riverside
  facilityId: "5DD050E0-2109-4E7E-84C0-EEE8F6588460", // Riverside Digital Outreach
  contact: {
    call: "(815)269-6582",
  },
  triageSubdomain: "riverside-outreach",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/riverside+logo.jpg",
    primary: "#00426C",
    primaryLight: "#B0D8FF",
    primaryDark: "#059CAE",
  },
  questionHeaderType: questionHeaderTypes.progress
};
const amitaEventWorkflow = {
  apiURL: apiURLs.production,
  gaCode: "G-4BFPRHEBFB",
  organizationId: "80567E9C-CD67-4509-BE93-59551048F565", // AMITA Health
  facilityId: "22663341-61D6-4C3B-A56F-E3BE7FC8C963", // Amita
  contentSelectionType: contentSelectionTypes.noContent,
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "WalgreensEventContact" },
    { route: { name: "screen-assign-advocate" } },
    { route: { name: "screen-additional-notes" } },
  ],
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/amita_health_logo.png",
  },
};
const brisson = {
  apiURL: apiURLs.production,
  authorize: true,
  organizationId: "6245007B-CEF9-41C4-9D9D-8B7893A8B523",
  facilityId: "AEFCDF9A-040F-4D81-910D-B5572209677C",
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "TroyContact" },
  ],
  contentSelectionType: contentSelectionTypes.noContent,
  questionHeaderType: questionHeaderTypes.progress,
};
//G-716SQBNYX9
const higiMichigan = {
  apiURL: apiURLs.production,
  gaCode: "G-716SQBNYX9",
  organizationId: "92BA851D-6CD6-434D-A8A0-CDC8041FA644",
  facilityId: "56C41281-0245-409B-81C2-1E0B7815C82D",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/higi_logo.png",
    logoSize: "h-8",
  },
  steps: [
    //{ route: { name: "screen-terms" } },
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  //content: ["DefaultContent1", "DefaultContent2", "DefaultContent3", null],
  content: ["DefaultContent1", "DefaultContent1", null],
  contact: {
    call: "(773) 232-1500",
  },
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};
//G-VV8F7VTYQZ
const higiIllinois = {
  apiURL: apiURLs.production,
  gaCode: "G-VV8F7VTYQZ",
  organizationId: "92BA851D-6CD6-434D-A8A0-CDC8041FA644",
  facilityId: "107A0178-2307-414B-B1BA-955E48739991",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/higi_logo.png",
    logoSize: "h-8",
  },
  steps: [
    //{ route: { name: "screen-terms" } },
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent1", null],
  contact: {
    call: "(773) 232-1500",
  },
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};
//G-WJ72X9063X
const higiFlorida = {
  apiURL: apiURLs.production,
  gaCode: "G-WJ72X9063X",
  organizationId: "92BA851D-6CD6-434D-A8A0-CDC8041FA644",
  facilityId: "4877C7D9-BFDD-4542-93C6-605825EC4EFF",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/higi_logo.png",
    logoSize: "h-8",
  },
  steps: [
    //{ route: { name: "screen-terms" } },
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent1", null],
  contact: {
    call: "(773) 232-1500",
  },
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const thrive = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "201E89A3-252C-4705-8656-32E388C00537",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent1", null],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const kansasBreastfeedingCoalition = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "3DD66733-B6E8-4217-A6E1-418872FF722A",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent1", null],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const beAble = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "69286687-16FE-4675-BB20-1331B4953BD4",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  content: ["DefaultContent1", "DefaultContent1", null],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const chcsek = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "55D468A9-86AB-4CFB-A810-D98263BE41E1",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const liberalCoalition = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "166AF2B1-23C2-4A6E-A497-EFB9EFCE2F93",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const wyandotteCommunityHealth = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "B4F4EC72-F68D-490A-8702-D67B65EFF4B9",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const liveWell = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "50FCEF40-8E9A-4084-B797-AD28C5DAE3F0",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const projectAccess = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "D95F82CA-FC35-45EF-A1E2-84064CF647BF",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const barberCountyUnited = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "748FB5C6-7BE0-4B14-8F91-18CC7AF868D1",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const neCommunityAction = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "077B725A-5341-4A2F-946E-9D6E25FB47A4",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const seCommunityAction = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "CF7D6785-D6F7-4962-A47B-4DA3FEE49ABA",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const elCentro = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "ABBFC63E-2050-4857-9BE7-17CA8295880E",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const qualityOfLifeCoalition = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "718741EF-7A30-4F46-A3C1-3448572F400E",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const impactUlysses = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "8F639EC2-0745-4C0F-95DE-B5088F2B9E03",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const growClayCounty = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "ECAA7126-AEBF-4803-81C0-73108CA95570",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const mitchellCounty = {
  apiURL: apiURLs.production,
  organizationId: "51DADF7B-1263-40FE-A22B-925632715A41",
  facilityId: "5181529E-1D98-466E-B122-FC53EC0CC99C",
  theme: {
    logo:
      "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/thrive_logo.png",
    logoSize: "h-8",
    primary: "#bf1e2e",
    primaryLight: "#be1d2d",
    primaryDark: "#851520",
    secondary: "#134486",
    secondaryLight: "#eff6ff",
    secondaryDark: "#1e40af",
  },
  steps: [
    { route: { name: "screen-questions" } },
    { route: { name: "screen-contact" }, component: "DefaultContact" },
    { route: { name: "screen-thank-you" } },
  ],
  contact: {
    call: "(620) 365-8128",
    website: "http://www.kansascares.org",
  },
  content: ["DefaultContent1", "DefaultContent1", null],
  questionHeaderType: questionHeaderTypes.progress,
  disableTriage: true,
};

const facilities = {
  localhost: defaultFacility,
  "192.168.1.87": defaultFacility,
  "advocatia.quick.benefitscreening.com": defaultFacility,
  "louisiana.gethelp.benefitscreening.com": uniteUsLouisiana,
  "amitaw.quick.benefitscreening.com": amitaw,
  "amita.quick.benefitscreening.com": amita,
  "ascensionillinois.quick.benefitscreening.com": ascension,
  "ascensionillinoisw.quick.benefitscreening.com": ascensionw,
  "froedtert.quick.benefitscreening.com": froedtert,
  "advocate.quick.benefitscreening.com": amitaEventWorkflow,
  "brisson.troy.benefitscreening.com": brisson,
  "riverside.quick.benefitscreening.com": riverside,
  "renown-regional.quick.benefitscreening.com": renownRegional,
  "higi-mi.quick.benefitscreening.com": higiMichigan,
  "higi-il.quick.benefitscreening.com": higiIllinois,
  "higi-fl.quick.benefitscreening.com": higiFlorida,
  "thrive.quick.benefitscreening.com": thrive,
  "ksbreastfeeding.quick.benefitscreening.com": kansasBreastfeedingCoalition,
  "chcsek.quick.benefitscreening.com": chcsek,
  "liberal-coalition.quick.benefitscreening.com": liberalCoalition,
  "chc-wyandotte.quick.benefitscreening.com": wyandotteCommunityHealth,
  "livewell.quick.benefitscreening.com": liveWell,
  "project-access.quick.benefitscreening.com": projectAccess,
  "barber-county.quick.benefitscreening.com": barberCountyUnited,
  "nek-cap.quick.benefitscreening.com": neCommunityAction,
  "sek-cap.quick.benefitscreening.com": seCommunityAction,
  "el-centro.quick.benefitscreening.com": elCentro,
  "qolc.quick.benefitscreening.com": qualityOfLifeCoalition,
  "impact-ulysses.quick.benefitscreening.com": impactUlysses,
  "grow-clay-county.quick.benefitscreening.com": growClayCounty,
  "mitchell-county.quick.benefitscreening.com" : mitchellCounty,
  "be-able.quick.benefitscreening.com": beAble
};

export {
  apiURLs,
  contentSelectionTypes,
  questionHeaderTypes,
  defaultFacility,
  facilities,
  brisson,
};

// const walgreens = {
//   apiURL: apiURLs.test,
//   gaCode: "G-1EQ2SPDCXY",
//   organizationId: "80567E9C-CD67-4509-BE93-59551048F565", // AMITA Health
//   facilityId: "82D3EB6D-CA89-4FBA-B951-259B8482D6BA", // Walgreens Pilot
//   contact: {
//     call: "(312) 584-1153",
//     textCode: "SHOP",
//     text: "(312) 586-9711",
//     website: "https://www.amitahealth.org/enroll/",
//   },
//   theme: {
//     logo:
//       "https://quickscreen-assets.s3.us-east-2.amazonaws.com/logos/amita_health_logo.png",
//     primary: "#7BA3DC",
//     primaryLight: "#E6F4F1",
//     primaryDark: "#5681B7",
//   },
// };
