<template>
  <div class="relative text-gray-700" v-click-away="hideLanguageSelect">
    <button
      class="text-sm flex justify-center items-center"
      @click="toggleLanguageSelect"
    >
      <span class="mr-1">{{ languages[languageCode] }}</span>
      <i class="fas fa-angle-down fa-sm"></i>
    </button>
    <div
      v-show="showLanguageSelect"
      class="w-32 text-sm py-1 bg-white border border-t-none rounded shadow-sm absolute mt-1 right-0 z-10"
    >
      <button
        v-for="key in Object.keys(languages)"
        :key="key"
        @click="updateLanguageCode(key)"
        class="w-full p-1 flex justify-center items-center hover:bg-gray-100"
      >
        {{ languages[key] }}
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/runtime-core";
import { languages } from "../../../common/constants";
import { useGtag } from "vue-gtag-next";
import useQuickscreen from "../../../composables/useQuickscreen";
import useBenefitRecord from "../../../composables/useBenefitRecord";
export default {
  setup() {
    const { query } = useGtag();
    const state = reactive({
      showLanguageSelect: false,
    });
    const toggleLanguageSelect = () => {
      state.showLanguageSelect = !state.showLanguageSelect;
    };
    const hideLanguageSelect = () => {
      if (state.showLanguageSelect) state.showLanguageSelect = false;
    };
    const displayLanguageSelect = () => {
      if (!state.showLanguageSelect) state.showLanguageSelect = true;
    };

    const { saveQuickscreen } = useQuickscreen();
    const { languageCode, setLanguageCode } = useBenefitRecord();

    const updateLanguageCode = async (newLanguageCode) => {
      if (languageCode.value !== newLanguageCode) {
        setLanguageCode(newLanguageCode);
        await saveQuickscreen();
        hideLanguageSelect();
        query("event", "set_language", {
          event_category: newLanguageCode,
        });
      }
    };

    return {
      languages,
      ...toRefs(state),
      toggleLanguageSelect,
      hideLanguageSelect,
      displayLanguageSelect,
      languageCode,
      updateLanguageCode,
    };
  },
};
</script>

<style></style>
