<template>
  <!-- Desktop -->
  <div class="my-4 hidden xl:flex flex-col">
    <div class="w-full flex bg-gray-100 pr-12">
      <slot
        name="header"
        _class="w-full grid text-xs font-medium uppercase text-gray-500 p-1"
      ></slot>
    </div>

    <slot
      name="row"
      _outerClass="w-full flex border-b"
      _innerClass="w-full grid text-sm p-1"
      _buttonClass="flex justify-end items-center"
    >
    </slot>
  </div>
  <!-- Mobile -->
  <div class="my-4 block xl:hidden">
    <slot
      name="col"
      _outerClass="w-full"
      _headerOuterClass="flex justify-between items-center"
      _headerClass="text-sm font-semibold px-1"
      _btnClass="flex justify-end items-center"
      _innerClass="my-2"
      _cellOuterClass="flex justify-between items-center border-b"
      _cellHeaderClass="w-1/2 bg-gray-100 text-xs font-medium uppercase text-gray-500 p-1"
      _cellBodyClass="w-1/2 text-sm px-1 flex justify-start items-center"
    ></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
