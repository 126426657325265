<template>
  <div class="w-full pb-2">
    <slot
      _headerClass="font-semibold text-xl"
      _subheaderClass="text-gray-500 text-sm"
    >
    </slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
