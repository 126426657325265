import { reactive, toRefs, computed } from "vue";
import { useGtag } from "vue-gtag-next";
import { eligibilities } from "../common/constants";
import benefitScreeningAPI from "../services/benefitScreeningAPI";
import useBenefitRecord from "./useBenefitRecord";
import {
  defaultFacility,
  facilities,
  contentSelectionTypes,
} from "../common/facilities";
import router from "../router";

const { query } = useGtag();
const { benefitRecord, setBenefitRecord } = useBenefitRecord();

const state = reactive({
  loading: false,
  transition: "right",
  complete: false,
  script: [],
  additionalQuestions: [],
  programs: [],
  currentStepIndex: 0,
  currentQuestion: null,
  backwardStack: [], // a stack of questionGuids // backward stack
  forwardStack: [], // a queue of questionGuids // forward stack
  questionListIndex: 0,
});
Object.assign(state, defaultFacility);
const requiredProperties = [
  "apiURL",
  "organizationId",
  "facilityId",
  "contact",
];
const optionalProperties = [
  "content",
  "contentSelectionType",
  "gaCode",
  "authorize",
  "steps",
  "contactComponent",
  "questionHeaderType",
  "triageSubdomain",
  "disableTriage",
];

//#region GETTERS
const getQuickscreenContent = computed(() => {
  switch (state.contentSelectionType) {
    case contentSelectionTypes.default:
      if (currentStep.value.route.name === "screen-questions") {
        return state.content[
          currentQuestionIndex.value % (state.content.length - 1)
        ];
      } else if (currentStep.value.route.name === "screen-contact")
        return state.content[state.content.length - 1];
      else return null;
    case contentSelectionTypes.firstPageOnly:
      return currentQuestionIndex.value === 0 ? state.content[0] : null;
    case contentSelectionTypes.noContent:
      return null;
    default:
      return null;
  }
});

const currentQuestionIndex = computed(() => {
  //const currentListScript = state.script.filter(question => question.listIndex === state.questionListIndex);
  return state.script.findIndex(
    (question) => question.questionGuid === state.currentQuestion
  );

  //return currentListScript.findIndex((question) => question.questionGuid === state.currentQuestion);
});

const eligiblePrograms = computed(() => {
  return state.programs.filter(
    (program) => program.eligibleStatus === eligibilities.eligible
  );
});

const progress = computed(() => {
  const progressList = state.programs.map((p) => p.progress);
  const sum = progressList.reduce((a, b) => a + b, 0);
  const count = state.programs.length;
  const avg = sum / count;
  const index = currentQuestionIndex.value;
  const avgInt = Math.floor(avg);

  if (avgInt == 5) {
    return avgInt * (index + 1);
  } else {
    return avgInt;
  }
});

const currentStep = computed(() => {
  return state.steps[state.currentStepIndex];
});
//#endregion

//#region ACTIONS
const loadFacility = () => {
  const hostname = window.location.hostname;
  if (hostname in facilities) setFacility(facilities[hostname]);
};

const setFacility = (facility) => {
  requiredProperties.forEach((key) => {
    state[key] = facility[key];
  });
  optionalProperties.forEach((key) => {
    if (key in facility) state[key] = facility[key];
  });
  if ("theme" in facility) setTheme(facility.theme);
};

const setTheme = (theme) => {
  const root = document.documentElement;
  if (theme.logo) state.theme.logo = theme.logo;
  if (theme.logoSize) state.theme.logoSize = theme.logoSize;
  if (theme.fontPrimary)
    root.style.setProperty("--font-primary", theme.fontPrimary);
  if (theme.primary) root.style.setProperty("--primary", theme.primary);
  if (theme.primaryLight)
    root.style.setProperty("--primary-light", theme.primaryLight);
  if (theme.primaryDark)
    root.style.setProperty("--primary-dark", theme.primaryDark);
  if (theme.secondary) root.style.setProperty("--secondary", theme.secondary);
  if (theme.secondaryLight)
    root.style.setProperty("--secondary-light", theme.secondaryLight);
  if (theme.secondaryDark)
    root.style.setProperty("--secondary-dark", theme.secondaryDark);
};

const loadQuickscreen = async () => {
  try {
    state.loading = true;

    const response = await benefitScreeningAPI.getQuickscreen();
    const { benefitRecord, script, programs } = response.data;

    setBenefitRecord(benefitRecord);
    state.script = script;
    state.programs = programs;
    setInitialQuestion();
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error);
    }

    state.loading = false;
  }
};

const pushStack = (questionGuid) => {
  if (!state.backwardStack.includes(questionGuid))
    state.backwardStack.push(questionGuid);
};

const pushQueue = (questionGuid) => {
  if (!state.forwardStack.includes(questionGuid))
    state.forwardStack.push(questionGuid);
};

const saveQuickscreen = async () => {
  try {
    state.loading = true;

    const payload = {
      benefitRecord,
      script: state.script,
      programs: state.programs,
    };
    const response = await benefitScreeningAPI.postQuickscreen(payload);

    const updatedBenefitRecord = response.data.benefitRecord;
    const { script, programs } = response.data;
    state.backwardStack = state.backwardStack.filter((questionGuid) => {
      return script.filter((question) => question.questionGuid === questionGuid)
        .length;
    });
    state.forwardStack = state.forwardStack.filter((questionGuid) => {
      return script.filter((question) => question.questionGuid === questionGuid)
        .length;
    });
    setBenefitRecord(updatedBenefitRecord);
    state.script = script;
    state.programs = programs;
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error);
    }
    state.loading = false;
  }
};

const setInitialQuestion = () => {
  state.currentQuestion = state.script[0].questionGuid;
  state.script.forEach((question) => {
    if (
      !state.forwardStack.includes(question.questionGuid) &&
      question.answer !== "" &&
      state.currentQuestion !== question.questionGuid
    )
      pushQueue(question.questionGuid);
  });
  //console.log("set initial question forward stack: ")
  //pushStack(state.currentQuestion);
};

const answerQuestion = (questionGuid, answer) => {
  const question = state.script.find(
    (question) => question.questionGuid === questionGuid
  );
  question.answer = answer;
  query("event", question.questionText, {
    event_category: question.answer,
  });
};

const filterQuestions = (question) =>
  question.answer === "" && question.listIndex === state.questionListIndex;

const nextQuestion = async () => {
  if (state.script[currentQuestionIndex.value].answer !== "") {
    await saveQuickscreen();
    pushStack(state.currentQuestion);
    console.log("next question backward stack: " + state.backwardStack);
    state.transition = "right";
    if (!getCurrentForwardStack().length) {
      const nextQuestion = state.script.filter(filterQuestions)[0];
      console.log("next question: " + nextQuestion);
      if (nextQuestion) {
        state.currentQuestion = nextQuestion.questionGuid;
      } else {
        nextStep();
      }
    } else {
      state.currentQuestion = getCurrentForwardStack().pop();
      state.forwardStack = state.forwardStack.filter(
        (q) => q != state.currentQuestion
      );
    }
  }
};

const answerAndProceed = async (questionGuid, answer) => {
  answerQuestion(questionGuid, answer);
  await nextQuestion();
};

const getCurrentListGuids = () => {
  return state.script
    .filter((q) => q.listIndex == state.questionListIndex)
    .map((q) => q.questionGuid);
};

const getCurrentBackwardStack = () => {
  const currentListGuids = getCurrentListGuids();
  return state.backwardStack.filter((q) => currentListGuids.includes(q));
};

const getCurrentForwardStack = () => {
  const currentListGuids = getCurrentListGuids();
  return state.forwardStack.filter((q) => currentListGuids.includes(q));
};

const previousQuestion = () => {
  pushQueue(state.currentQuestion);
  const currentBackwardStack = getCurrentBackwardStack();
  console.log("previous question current backward: " + currentBackwardStack);
  console.log("previous backward: " + state.backwardStack);
  // console.log("previous question filtered stack: " + currentListStack);
  // console.log("current list stack:" + currentListStack);
  if (currentBackwardStack.length > 0) {
    state.transition = "left";
    const currentQuestion = currentBackwardStack.pop();
    state.backwardStack = state.backwardStack.filter(
      (q) => q != currentQuestion
    );
    state.currentQuestion = currentQuestion;
  } else {
    //decrementListIndex();
    console.log("hit previous step!");
    previousStep();
  }
};

const incrementListIndex = () => {
  state.questionListIndex++;
};

const decrementListIndex = () => {
  console.log("decrementing: " + state.questionListIndex);
  state.questionListIndex--;
};

const nextStep = () => {
  if (state.currentStepIndex !== state.steps.length - 1) {
    state.transition = "right";
    if (state.steps[state.currentStepIndex].route.name === "screen-questions") {
      incrementListIndex();
      console.log(
        "index after next step increment: " + state.questionListIndex
      );
      const nextQuestion = state.script.filter(filterQuestions)[0];
      if (getCurrentForwardStack().length > 0) {
        state.currentQuestion = getCurrentForwardStack().pop();
        state.forwardStack = state.forwardStack.filter(
          (q) => q != state.currentQuestion
        );
      } else if (nextQuestion) {
        state.currentQuestion = nextQuestion.questionGuid;
      }
    }
    state.currentStepIndex++;
    router.push(state.steps[state.currentStepIndex].route);
  }
};
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const lastStep = async () => {
  await delay(300);
  router.push(state.steps[state.steps.length - 1].route);
};

const previousStep = () => {
  if (state.currentStepIndex !== 0) {
    state.transition = "left";
    state.currentStepIndex--;
    if (state.steps[state.currentStepIndex].route.name === "screen-questions") {
      decrementListIndex();
      console.log("list index now: " + state.questionListIndex);
      console.log("backward stack: " + getCurrentBackwardStack());
      state.currentQuestion = getCurrentBackwardStack().pop();
      state.backwardStack = state.backwardStack.filter(
        (q) => q != state.currentQuestion
      );
      console.log("state backward stack: " + state.backwardStack);
    }

    router.push(state.steps[state.currentStepIndex].route);
  }
};

const setComplete = (complete) => {
  state.complete = complete;
};

//#endregion

export default () => {
  return {
    getQuickscreenContent,
    currentQuestionIndex,
    eligiblePrograms,
    progress,
    currentStep,
    ...toRefs(state),
    loadFacility,
    setInitialQuestion,
    loadQuickscreen,
    saveQuickscreen,
    answerQuestion,
    previousQuestion,
    nextQuestion,
    answerAndProceed,
    setComplete,
    nextStep,
    previousStep,
    incrementListIndex,
    decrementListIndex,
    getCurrentListGuids,
    getCurrentBackwardStack,
    getCurrentForwardStack,
    lastStep,
    delay,
  };
};
