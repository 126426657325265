<template>
  <div
    class="w-full h-full relative pt-14 overflow-hidden"
    :style="{ maxHeight: `${height}px` }"
  >
    <Header />
    <JsonViewer :value="screenings" copyable boxed sort />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "@vue/runtime-core";
import benefitAPI from "../../services/benefitAPI";
import useFacility from "../../composables/useFacility";
import useWindowSize from "../../composables/useWindowSize";
import useAuth from "../../composables/useAuth";
import Header from "../../components/common/layout/Header.vue";

export default {
  components: {
    Header,
  },
  setup() {
    const { loadFacility } = useFacility();
    const { width, height } = useWindowSize();
    const { accessToken } = useAuth();

    const state = reactive({
      screenings: [],
    });

    const setScreenings = (screenings) => {
      state.screenings = screenings;
    };

    onMounted(async () => {
      await loadFacility();

      try {
        const response = await benefitAPI.getScreenings(accessToken.value);
        setScreenings(response.data.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error);
        }
      }
    });

    return {
      width,
      height,
      ...toRefs(state),
    };
  },
};
</script>
