<template>
  <div class="w-full relative" v-show="isLoaded">
    <img :src="getImageSrc()" alt="" @load="loaded" />
    <div
      class="absolute top-0 left-0 right-0 bottom-0 text-center flex justify-end"
    >
      <p
        class="w-full text-white font-bold text-xl sm:text-3xl p-2 pt-12 font-secondary"
      >
        {{ getQuestionBlurb() }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useLanguage from "../../../composables/useLanguage";
import useQuickscreen from "../../../composables/useQuickscreen";

export default {
  setup() {
    const { language } = useLanguage();
    const isLoaded = ref(false);
    const { currentQuestion, script } = useQuickscreen();
    //const script = useQuickscreen();
    //const questionBlurb = ref("");

    const getQuestionBlurb = () => {

      const question = script.value.filter(
        (q) => q.questionGuid == currentQuestion.value
      )[0];
      return question.imageText;
    };

    const getImageSrc = () => {
      const question = script.value.filter(
        (q) => q.questionGuid == currentQuestion.value
      )[0];
      if (question.imageSrc.length > 0) {
        return question.imageSrc;
      } else {
        return "https://quickscreen-assets.s3.us-east-2.amazonaws.com/img/iStock-131976321_transparent_opacity.png";
      }
    };
    const loaded = () => {
      isLoaded.value = true;
    };

    return {
      language,
      isLoaded,
      loaded,
      currentQuestion,
      script,
      getQuestionBlurb,
      getImageSrc,
    };
  },
};
</script>
