<template>
  <Modal :showModal="showModal">
    <div
      class="w-full max-w-md rounded relative bg-white shadow"
      v-click-away="hideModal"
    >
      <div
        class="w-full border-b bg-gray-50 flex justify-between items-center p-3 rounded-t"
      >
        <div>
          <h1 class="text-sm uppercase text-gray-500 font-semibold">
            Terms &amp; Conditions
          </h1>
        </div>
        <button
          @click="hideModal"
          class="hover:bg-gray-200 w-6 h-6 rounded flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ai ai-Cross"
          >
            <path d="M20 20L4 4m16 0L4 20" />
          </svg>
        </button>
      </div>
      <div
        style="
          height: 320px;
          overflow: scroll;
          margin-top: 20px;
          padding: 15px;
          overflow-x: hidden;
        "
      >
        <p>
          Welcome to Advocatia. These Terms of Service set forth the terms and
          conditions that apply to your access and use of our website
          www.advocatia.io (the “Website”) and our Benefit Triage platform (the
          “Platform”) (collectively, the “Services”).
        </p>
        <br />
        <p>
          Please review the following terms carefully. If you do not agree to
          these terms, you may not access or use the Services. The terms
          “Advocatia” or “us” or “we” or “our” refer to Advocatia Solutions,
          Inc., the owner of the Services. The terms “you” or “your” refer to
          the user or viewer of the Services.
        </p>
        <br />
        <p>1. Acceptance</p>
        <br />
        <p>
          You agree to the terms and conditions set forth in these Terms of
          Service (the “Terms of Service”). These Terms of Services are the only
          agreement between us and you with respect to the subject matter of
          these Terms of Service. You must be at least the age of legal majority
          where you live (which is currently 18 years old in most states) to use
          our Services.
        </p>
        <br />
        <p>2. Privacy</p>
        <br />
        <p>
          The Privacy Policy is incorporated by reference into these Terms of
          Service, and your agreement to be bound by these Terms of Service
          means you are also bound by the Privacy Policy.
        </p>
        <br />
        <p>3. Use of Services</p>
        <br />
        <p>
          Certain sections of, or offerings from, the Services may require you
          to register. If registration is requested, you agree to provide us
          with accurate, complete registration information. Each registration is
          for your personal use only and not on behalf of any other person or
          entity. We do not permit (a) any other person using the registered
          sections under your name; or (b) access through a single name being
          made available to multiple users on a network. You are responsible for
          preventing such unauthorized use. Any password or right given to you
          to obtain information is not transferable or assignable.
        </p>
        <br />
        <p>
          You authorize Advocatia, directly or through third parties, to make
          any inquiries we consider necessary to validate your identity.
          Advocatia reserves the right to close, suspend, or limit access to
          your account and/or the Services for any reason.
        </p>
        <br />
        <p>4. Intellectual Property</p>
        <br />
        <p>
          The content, organization, graphics, design, compilation, magnetic
          translation, digital conversion and other matters related to the
          Services are protected under applicable copyrights, trademarks and
          other proprietary (including but not limited to intellectual property)
          rights. The copying, redistribution, use or publication by you of any
          such matters or any part of the Services, except as allowed by these
          Terms of Service below, is strictly prohibited. You do not acquire
          ownership rights to any content or other materials viewed through the
          Services. The postings of information or materials on the Services
          does not constitute a waiver of any right in such information and
          materials. Some of the content on the Services is the copyrighted work
          of third parties.
        </p>
        <br />
        <p>
          Advocatia’s name, logo, and all related names, logos, product and
          service names, designs, and slogans, whether registered or
          unregistered, are trademarks of Advocatia or its affiliates or
          licensors. You must not use such marks without the prior written
          permission of Advocatia. All other names, logos, product and service
          names, designs, and slogans on the Services are the trademarks of
          their respective owners.
        </p>
        <br />
        <p>5. Limited License; Restrictions on Use</p>
        <br />
        <p>
          You are granted a non-exclusive, non-transferable, revocable license
          to access and use the Services strictly in accordance with these Terms
          of Service. All other rights are reserved.
        </p>
        <br />
        <p>
          Your license for access and use of the Services and any information,
          products, services and materials they contain (collectively defined as
          “Content”) therein are subject to the following restrictions and
          prohibitions on use: you may not (a) copy, republish, display,
          distribute, transmit, sell, rent, lease, loan or otherwise make
          available in any form or by any means all or any portion of the
          Services or any Content retrieved therefrom; (b) create compilations
          or derivative works of any Content from the Services; (c) use any
          Content from the Services in any manner that may infringe any
          copyright, intellectual property right, proprietary right, or property
          right of us or any third parties; (d) remove, change or obscure any
          copyright notice or other proprietary notice or terms of use contained
          in the Services; (e) make any portion of the Services available
          through any timesharing system, service bureau, the Internet or any
          other technology now existing or developed in the future; (f) remove,
          decompile, disassemble or reverse engineer any software in the
          Services; (g) use any automatic or manual process to harvest
          information from the Services; (h) use the Services in a manner that
          violates any state or federal law; or (i) use the Services in any way
          except as permitted by these Terms of Service.
        </p>
        <br />
        <p>6. Disclaimer</p>
        <br />
        <p>
          The information and Content from or through the Services are provided
          “as-is,” “as available,” with “all faults”, and warranties, express or
          implied, are disclaimed (including but not limited to the disclaimer
          of any implied warranties of merchantability and fitness for a
          particular purpose). The information and services may contain bugs,
          errors, problems or other limitations. We and our Affiliated Parties
          have no liability whatsoever for your use of any information or
          services. No advice or information, whether oral or written, obtained
          by you from us through the Services or otherwise shall create any
          warranty, representation or guarantee not expressly stated in these
          Terms of Service.
        </p>
        <br />
        <p>
          Third-party content may appear on the Services or may be accessible
          via links from the Services. We are not responsible for and assume no
          liability for any mistakes, misstatements of law, defamation,
          omissions, falsehood, obscenity, pornography or profanity in the
          statements, opinions, representations or any other form of content on
          the Services. You understand that the information and opinions in the
          third-party content represent solely the thoughts of the author and
          are neither endorsed by nor do they necessarily reflect our belief.
        </p>
        <br />
        <p>7. Indemnification</p>
        <br />
        <p>
          You agree to indemnify, defend and hold us and our partners, agents,
          officers, directors, employees, subcontractors, successors, assigns,
          third party suppliers or information and documents, attorneys,
          advertisers, products and service providers, and affiliates
          (collectively, “Affiliated Parties”) harmless from any liability,
          loss, claim and expense, including reasonable attorney’s fees, related
          to your violation of these Terms of Service or use of the Services.
        </p>
        <br />
        <p>8. Limitation of Liability</p>
        <br />
        <p>
          We and any Affiliated Party are not liable for any indirect, special,
          incidental, punitive, or consequential damages (including damages for
          loss of business, loss of profits, litigation, or the like), whether
          based on breach of contract, breach of warranty, tort (including
          negligence), product liability, or otherwise, even if advised of the
          possibility of such damages. The negation and limitation of damages
          set forth above are fundamental elements of the basis of the bargain
          between us and you. The Services and the Content presented would not
          be provided without such limitations.
        </p>
        <br />
        <p>
          The aggregate liability of us and any Affiliated Party in connection
          with any claim arising out of or relating to the Services and/or the
          Content provided herein or hereby shall not exceed $100 and that
          amount shall be in lieu of all other remedies which you may have
          against us and any Affiliated Party.
        </p>
        <br />
        <p>9. Miscellaneous</p>
        <br />
        <p>
          Modifications. These Terms of Service may be amended at any time by us
          without specific notice to you. The latest Terms of Service will be
          posted on our website, and you should review the Terms of Service
          prior to using the Services, so you are aware of any changes. Your
          continued use of the Services following the posting of revised Terms
          of Service means that you accept and agree to the changes.
        </p>
        <br />
        <p>
          Governing Law and Jurisdiction. These Terms of Service shall be
          governed by the laws of the State of Illinois without regard to
          conflicts or choice of law rules or principles. Any action to enforce
          these Terms of Service shall be filed in the State or Federal Courts
          located in Cook County, Illinois, only.
        </p>
        <br />
        <p>
          Unlawful Activity. We reserve the right to investigate complaints or
          reported violations of these Terms of Service and to take any action
          we deem appropriate, including but not limited to reporting any
          suspected unlawful activity to law enforcement officials, regulators,
          or other third parties and disclosing any information necessary or
          appropriate to such persons or entities relating to your profile,
          email addresses, usage history, posted materials, IP addresses and
          traffic information.
        </p>
        <br />
        <p class="contactInfo">10. Contact Information</p>
        <br />
        <p>
          If you have any questions or concerns about these Terms of Service,
          please email us at info@advocatia.io.
        </p>
        <br />
      </div>
      <div
        class="border-t p-3 flex justify-between items-center bg-gray-50 rounded-b"
      >
        <button
          @click="hideModal"
          class="py-3 font-medium text-xs w-1/2 text-blue-500"
        >
          Cancel
        </button>
        <button
          @click="
            hideModal();
            acceptTerms();
          "
          class="py-3 w-1/2 text-xs btn-primary rounded border font-medium"
        >
          Acknowledge
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../../common/ui/Modal.vue";
//import Select from "../../common/ui/Select.vue";
//import TextField from "../../common/ui/TextField.vue";
import { onMounted } from "@vue/runtime-core";

export default {
  components: {
    Modal,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(_, { emit }) {
    const hideModal = () => {
      emit("hideModal");
    };
    const acceptTerms = () => {
      emit("addAcknowledgement");
    };
    onMounted(async () => {
    });
    return {
      hideModal,
      acceptTerms,
    };
  },
};
</script>
