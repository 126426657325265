import { reactive, toRefs } from "vue";

const state = reactive({
  termsAndConditionsAcknowledged: false,
});

const acknowledgeTerms = () => {
  state.termsAndConditionsAcknowledged = true;
};

const getTermsAcknowledgement = () => {
  return state.termsAndConditionsAcknowledged;
};
const swapAcknowledgement = () => {
  state.termsAndConditionsAcknowledged = !state.termsAndConditionsAcknowledged;
};
export default () => {
  return {
    ...toRefs(state),
    acknowledgeTerms,
    swapAcknowledgement,
    getTermsAcknowledgement,
  };
};
