<template>
  <PageWrapper
    ><div class="w-full">
      <Header v-slot="{ _headerClass, _subheaderClass }">
        <h1 :class="_headerClass">Contact</h1>
        <p :class="_subheaderClass">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi
          fugit enim facere aliquid, vitae sapiente distinctio voluptatem ut.
          Quae, consequuntur!
        </p>
      </Header>

      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-4">
        <TextField
          :label="'Email'"
          :placeholder="'Enter Email'"
          :modelValue="email"
          @textChange="setEmail"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
        <TextField
          :label="'Phone Number'"
          :placeholder="'Enter Phone Number'"
          :mask="'(###) ###-####'"
          :pattern="'[0-9]*'"
          :modelValue="phone"
          @textChange="setPhone"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
      </div>
      <div class="w-full flex justify-end items-center">
        <router-link
          :to="{ name: 'details' }"
          class="btn-secondary py-2 w-24 rounded text-sm mx-2"
        >
          Back</router-link
        ><router-link
          :to="{ name: 'address' }"
          class="btn-primary text-sm py-2 w-24 rounded"
          >Continue</router-link
        >
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import useBenefitRecord from "../../composables/useBenefitRecord";
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
import TextField from "../../components/common/ui/TextField.vue";
import Header from "../../components/home/ui/Header.vue";
export default {
  components: {
    PageWrapper,
    TextField,
    Header,
  },
  setup() {
    const { email, phone, setEmail, setPhone } = useBenefitRecord();

    return { email, phone, setEmail, setPhone };
  },
};
</script>
