<template>
  <transition name="fade" appear>
    <div
      class="w-screen overflow-y-auto absolute top-0 left-0 z-30 modal px-2 pb-2 pt-2 sm:pt-14"
      :class="modalClasses"
      :style="{ height: `${height}px` }"
      v-show="showModal"
    >
      <slot v-if="showModal">
        <div class="w-64 h-64 bg-white border shadow-md">Modal Content</div>
      </slot>
    </div>
  </transition>
</template>

<script>
import useWindowSize from "../../../composables/useWindowSize";
export default {
  props: {
    modalClasses: {
      type: String,
      required: false,
      default: "flex justify-center items-start",
    },
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    const { height } = useWindowSize();

    return { height };
  },
};
</script>
