<template>
  <PageWrapper
    ><div class="w-full">
      <Header v-slot="{ _headerClass, _subheaderClass }">
        <h1 :class="_headerClass">About you</h1>
        <p :class="_subheaderClass">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi
          fugit enim facere aliquid, vitae sapiente distinctio voluptatem ut.
          Quae, consequuntur!
        </p>
      </Header>

      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-4">
        <TextField
          label="First name"
          placeholder="Enter first name"
          :modelValue="firstName"
          @textChange="setFirstName"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
        <TextField
          label="Last name"
          placeholder="Enter last name"
          :modelValue="lastName"
          @textChange="setLastName"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
        <Select
          :label="'Sex'"
          :placeholder="'Select sex'"
          :options="[
            { value: 'Male', text: 'Male' },
            { value: 'Female', text: 'Female' },
          ]"
          :modelValue="sex"
          @select="setSex"
          labelClasses="font-medium text-xs mb-2"
          selectClasses="bg-gray-100 py-2 pl-4 pr-2 border border-gray-300 rounded flex justify-between items-center"
          focusClasses="bg-gray-100 border border-primary rounded"
        />
        <TextField
          label="Birthday"
          placeholder="MM/DD/YYYY"
          :modelValue="dob"
          @textChange="setDOB"
          :mask="'##/##/####'"
          :pattern="'[0-9]*'"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
        <TextField
          label="SSN"
          placeholder="Enter SSN"
          :modelValue="ssn"
          @textChange="setSSN"
          :mask="'###-##-####'"
          :pattern="'[0-9]*'"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
      </div>
      <div class="w-full flex justify-end items-center">
        <router-link
          :to="{ name: 'screen' }"
          class="btn-secondary py-2 w-24 rounded text-sm mx-2"
        >
          Back</router-link
        ><router-link
          :to="{ name: 'contact' }"
          class="btn-primary text-sm py-2 w-24 rounded"
          >Continue</router-link
        >
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import useBenefitRecord from "../../composables/useBenefitRecord";
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
import TextField from "../../components/common/ui/TextField.vue";
import Select from "../../components/common/ui/Select.vue";
import Header from "../../components/home/ui/Header.vue";
export default {
  components: {
    PageWrapper,
    TextField,
    Select,
    Header,
  },
  setup() {
    const {
      firstName,
      lastName,
      sex,
      formattedDOB,
      ssn,
      setFirstName,
      setLastName,
      setSex,
      setDOB,
      setSSN,
    } = useBenefitRecord();

    return {
      firstName,
      lastName,
      sex,
      dob: formattedDOB,
      ssn,
      setFirstName,
      setLastName,
      setSex,
      setDOB,
      setSSN,
    };
  },
};
</script>
