<template>
  <div class="w-full p-2" :class="questionWrapperClass">
    <button
      class="w-full flex justify-between items-center py-1"
      @click="toggleMinimized"
    >
      <h2 class="text-sm w-full text-left">
        {{ questionText }}
      </h2>
      <div class="flex justify-end items-center flex-shrink-0">
        <p class="text-xs text-gray-600 bg-gray-200 px-1 rounded">
          {{ answer }}
        </p>
        <svg
          v-if="minimized"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="ai ai-ChevronRight text-gray-500 ml-1"
        >
          <path d="M8 4l8 8-8 8" />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="ai ai-ChevronDown text-gray-800 ml-1"
        >
          <path d="M4 9l8 8 8-8" />
        </svg>
      </div>
    </button>

    <transition name="slide-down" v-if="!minimized">
      <div
        class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-4 mb-2"
        v-if="
          controlType === controlTypes.dropdown && availableAnswers.length < 10
        "
      >
        <button
          @click="answerQuestion(availableAnswer)"
          v-for="availableAnswer in availableAnswers"
          :key="availableAnswer"
          class="text-sm px-2 py-3 border shadow-sm hover:shadow bg-white rounded flex justify-start items-center text-left"
        >
          <svg
            v-if="answer !== availableAnswer"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ai ai-Circle mr-2 text-gray-400"
          >
            <circle cx="12" cy="12" r="10" />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ai ai-CircleCheck mr-2 text-primary"
          >
            <path d="M8 12.5l3 3 5-6" />
            <circle cx="12" cy="12" r="10" />
          </svg>
          <p>{{ availableAnswer }}</p>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "@vue/reactivity";
import { controlTypes, questionScopes } from "../../../common/constants";
export default {
  props: {
    questionGuid: {
      type: String,
      required: true,
    },
    questionText: {
      type: String,
      required: true,
    },
    availableAnswers: {
      type: Array,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
    controlType: {
      type: String,
      required: true,
    },
    questionScope: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  emits: ["answerQuestion"],
  setup(props, { emit }) {
    const state = reactive({
      minimized: props.answer !== "",
    });

    const toggleMinimized = () => {
      state.minimized = !state.minimized;
    };

    const answerQuestion = (answer) => {
      emit("answerQuestion", props.questionGuid, answer);
      state.minimized = true;
    };

    const questionWrapperClass = computed(() => {
      let classes = [];
      if (props.questionScope === questionScopes.screening)
        classes.push(["bg-blue-50"]);
      if (state.minimized) classes.push(["border-b", "hover:bg-gray-100"]);
      else classes.push(["pb-4", "border-b"]);

      return classes;
    });

    return {
      controlTypes,
      toggleMinimized,
      ...toRefs(state),
      answerQuestion,
      questionWrapperClass,
    };
  },
};
</script>

<style></style>
