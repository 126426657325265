export const spanish = {
  welcomeBenefitsProgramScreener:
    "Bienvenido al evaluador del programa de beneficios",
  progress: "Progreso",
  getStarted: "¡Empecemos!",
  next: "Próxima",
  back: "Previa",
  basedOnYourAnswersYouQualifyFor:
    "A continuación se detallan los beneficios para los que puede calificar:",
  uniteUsCongrats:
    "¡Felicitaciones por descubrir para qué programas podría ser elegible! Haga clic en el botón a continuación para regresar al sitio de Unite Louisiana y solicitar asistencia con la inscripción de beneficios y otros servicios que pueda necesitar.",
  continueSignUp: "Continuar Registrarse",
  needAssistance:
    "¿Necesita ayuda para encontrar beneficios? Estamos aquí para ayudar, GRATIS.",
  quickEasyFree: "Es fácil, es rápido, es GRATIS",
  question: "Pregunta",
  oneInFiveQualify: "1/5 Las estadounidenses califican para Medicaid.",
  medicaidCoversBroadRange:
    "Medicaid cubre una amplia gama de servicios médicos y de atención a largo plazo.",
  yourResults: "Tus resultados",
  friendlyAdvocatiaRepContact:
    "Proporcione su información de contacto y un representante útil lo ayudará de forma gratuita. Su información no será compartida",
  startEnrollment: "Iniciar inscripción",
  programs: "Programas",
  ratherTalkToAdvisor:
    "¿Prefieres hablar con uno de nuestros asesores de inscripción?",
  weAreHereToHelp: "Estamos aquí para ayudar.",
  callUsAt: "Llamenos al",
  text: "texto",
  to: "para",
  or: "o",
  visitUsHere: "visítanos aquí.",
  ifYouMake30Or100:
    "Si gana $30k o $110K, puede obtener ayuda con la cobertura. Podemos mostrarte cómo.",
  submit: "Entregar",
  startProcess: "Iniciar el proceso yo mismo",
  wantToStart:
    "¿Quiere iniciar el proceso usted mismo? Ingrese su fecha de nacimiento para comenzar.",
  requestAssistance: "Solicitar Asistencia",
  selectAnOption: "Seleccione una opción",
  enterNumber: "Ingrese un numero",
  termsConditions: "Términos y condiciones",
  firstName: "Ingrese el nombre",
  lastName: "Introduzca el apellido",
  enterEmail: "Ingrese correo electrónico",
  enterPhone: "Introduce el teléfono",
  enterTimeOfDay: "Introduzca la hora del día y/o los días de la semana",
  acknowledgeTerms: "Yo reconozco los",
};
